import React from "react";

const FileIcon = () => {
  return (
    <svg
      fill="#000000"
      height="32px"
      width="32px"
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
    >
      <g>
        <path d="M36.491,22h-25c-0.553,0-1,0.448-1,1s0.447,1,1,1h25c0.553,0,1-0.448,1-1S37.044,22,36.491,22z" />
        <path d="M11.491,16h10c0.553,0,1-0.448,1-1s-0.447-1-1-1h-10c-0.553,0-1,0.448-1,1S10.938,16,11.491,16z" />
        <path d="M37.491,31c0-0.552-0.447-1-1-1h-25c-0.553,0-1,0.448-1,1s0.447,1,1,1h25C37.044,32,37.491,31.552,37.491,31z" />
        <path d="M29.491,39c0-0.552-0.447-1-1-1h-17c-0.553,0-1,0.448-1,1s0.447,1,1,1h17C29.044,40,29.491,39.552,29.491,39z" />
        <path d="M11.491,46c-0.553,0-1,0.448-1,1s0.447,1,1,1h14c0.553,0,1-0.448,1-1s-0.447-1-1-1H11.491z" />
        <path d="M2.491,2h29v14h14v15h2V14.586L32.905,0H0.491v60h37v-2h-35V2z M33.491,3.414L44.077,14H33.491V3.414z" />
        <path
          d="M59.232,58.291l-5.97-6.244c1.746-1.919,2.82-4.458,2.82-7.251C56.083,38.843,51.24,34,45.287,34
		s-10.796,4.843-10.796,10.796s4.843,10.796,10.796,10.796c2.442,0,4.689-0.824,6.499-2.196l6.001,6.276
		c0.196,0.206,0.459,0.309,0.723,0.309c0.249,0,0.497-0.092,0.691-0.277C59.599,59.323,59.614,58.689,59.232,58.291z M36.491,44.796
		c0-4.85,3.946-8.796,8.796-8.796s8.796,3.946,8.796,8.796s-3.946,8.796-8.796,8.796S36.491,49.646,36.491,44.796z"
        />
      </g>
    </svg>
  );
};

export default FileIcon;
