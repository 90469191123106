import React from 'react'
import {
    Section,
    SectionTitle,
    Row,
    Column,
    SectionSeparator,
    WrapRow,
    InfoPhrase,
} from '../../shared/shared.styled'
import Checkbox from '../../shared/components/checkbox.component'
import { useFormikContext } from 'formik'
import { CreateVehicleRequest } from '../admin.interfaces'
import styled from 'styled-components'
import { useIsAdmin } from '../../shared/hooks/use-is-admin.hook'
import { AvailabilityEnum } from '../../shared/shared.enums'
import {
    useIsEditor2,
    useIsEditor3,
} from '../../shared/hooks/use-is-editor.hook'
import { EuroInput } from '../../shared/components/euro-input.component'

interface PromotionSectionProps {
    isEditing?: boolean
}

const PromotionSection = ({ isEditing }: PromotionSectionProps) => {
    const isAdmin = useIsAdmin()
    //const isEditor1 = useIsEditor1()
    const isEditor2 = useIsEditor2()
    const isEditor3 = useIsEditor3()
    const { values, handleChange, setFieldValue, errors } = useFormikContext<
        CreateVehicleRequest
    >()
    return (
        <Section>
            <Row>
                <SectionTitle>Promoção</SectionTitle>
            </Row>
            <Row>
                <InfoPhrase>
                    Para atividades promocionais é necessário preencher os
                    campos abaixo
                </InfoPhrase>
            </Row>
            <SectionSeparator />
            <ResponsiveRow style={{ alignItems: 'flex-start' }}>
                <Column style={{ flex: 0.3 }}>
                    <Checkbox
                        disabled={
                            (!isAdmin && !isEditor3 && !isEditor2) ||
                            !isEditing ||
                            values.availability !==
                                AvailabilityEnum.READY_FOR_SALE
                        }
                        label="É um veículo com promoção?"
                        checked={
                            values.isOnPromotion &&
                            values.availability ===
                                AvailabilityEnum.READY_FOR_SALE
                        }
                        onChange={e =>
                            setFieldValue('isOnPromotion', e.target.checked)
                        }
                    />
                </Column>
                <Column style={{ flex: 0.2 }}>
                    <EuroInput
                        inEdition={isAdmin && isEditing}
                        name="promotionPrice"
                        placeholder="Ex: 21000"
                        onChange={e =>
                            setFieldValue(
                                'promotionPrice',
                                e.target.value.length > 0
                                    ? e.target.value
                                    : null
                            )
                        }
                        type="number"
                        step="0.01"
                        title="Valor Promocional"
                        value={values.promotionPrice || 0}
                        error={errors.promotionPrice}
                    />
                </Column>
            </ResponsiveRow>
        </Section>
    )
}

export default PromotionSection

const ResponsiveRow = styled(Row as any)`
    ${WrapRow} {
        > div {
            width: fit-content !important;
        }
        > div:not(:last-child) {
            margin-right: 24px;
        }
    }

    @media screen and (max-width: 767px) {
        flex-direction: column;
    }
`
