import React from "react";

const ContactIcon = () => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 117.13 122.88"
    >
      <title>Contactos</title>
      <path d="M105.87,80.26h7.88a3.4,3.4,0,0,1,1.18.2,3.2,3.2,0,0,1,1.23.76h0a3.17,3.17,0,0,1,.77,1.22h0a3.75,3.75,0,0,1,.2,1.18V99.52a3,3,0,0,1-.11.83l-.06.23a3.11,3.11,0,0,1-.74,1.19,3.27,3.27,0,0,1-.81.6,2.93,2.93,0,0,1-1.34.32h-8.2V104a19,19,0,0,1-18.93,18.93h-68A19,19,0,0,1,0,104v-85A19,19,0,0,1,18.93,0h68a19,19,0,0,1,18.93,18.93v1.83h7.88a3.29,3.29,0,0,1,1.44.32,3.18,3.18,0,0,1,1.23,1h0a3.53,3.53,0,0,1,.55,1.05h0a3.58,3.58,0,0,1,.18,1.11v15.9a2.79,2.79,0,0,1-.19,1,2,2,0,0,1-.18.4,3.05,3.05,0,0,1-.79.94,3.79,3.79,0,0,1-.56.36h0a2.94,2.94,0,0,1-1.33.33h-8.2v7h7.88a3.37,3.37,0,0,1,1.44.32h0a3.25,3.25,0,0,1,1.23,1h0a3.46,3.46,0,0,1,.5.89,3.34,3.34,0,0,1,.22,1.19v16a3,3,0,0,1-.32,1.33,3.23,3.23,0,0,1-.59.82h0a2.92,2.92,0,0,1-.83.6h0a3,3,0,0,1-1.32.32h-8.2v7.59ZM52.94,27.46a34,34,0,1,1-24,10,33.87,33.87,0,0,1,24-10ZM73.6,40.77a29.23,29.23,0,0,0-44,38.2c3.5-1.58,11.14-2.23,14.42-4.51a11.81,11.81,0,0,0,.79-1.53c.39-.91.76-1.9,1-2.57a32.4,32.4,0,0,1-2.58-3.68l-2.61-4.16a7.68,7.68,0,0,1-1.49-3.8,3,3,0,0,1,.26-1.36A2.66,2.66,0,0,1,40.86,56a63.89,63.89,0,0,1-.13-7.48,11.17,11.17,0,0,1,.32-1.69,10,10,0,0,1,4.42-5.63,13.49,13.49,0,0,1,3.7-1.64c.83-.24-.71-2.89.15-3,4.15-.42,10.87,3.37,13.77,6.5a10.19,10.19,0,0,1,2.56,6.41l-.16,6.79h0a1.87,1.87,0,0,1,1.37,1.42,5.88,5.88,0,0,1-.72,3.57h0l0,.09-3,4.91a25.92,25.92,0,0,1-3.66,5l.4.57a17.81,17.81,0,0,0,2,2.51.27.27,0,0,1,.07.08c3.25,2.3,10.92,3,14.44,4.53A29.25,29.25,0,0,0,73.6,40.77ZM86.94,7.12h-68A11.88,11.88,0,0,0,7.12,18.93v85a11.88,11.88,0,0,0,11.81,11.81h68A11.88,11.88,0,0,0,98.75,104v-85A11.88,11.88,0,0,0,86.94,7.12Z" />
    </svg>
  );
};

export default ContactIcon;
