import React, { useState } from "react";
import ReactModal from "react-modal";
import { Column, Row } from "../../../shared/shared.styled";
import { Formik } from "formik";
import TextInput from "../../../shared/components/text-input.component";
import PrimaryButton from "../../../shared/components/button.component";
import * as Yup from "yup";
import styled from "styled-components";
import { Typography, Colors } from "../../../configs/styled.config";
import CloseIcon from "../../../assets/icons/close.icon";
import { VehicleFile } from "../../../shared/shared.models";
import { SelectedFile } from "../media-section.component";
import UploadFile from "../upload-files.component";

interface AddFileModalProps {
  addFile: (val: VehicleFile, fileUploaded: File) => Promise<VehicleFile>;
  editFile: (values: SelectedFile) => void;
  hideModal: () => void;
  selectedFile: SelectedFile | undefined;
}

const formikValidation = Yup.object().shape({
  description: Yup.string().required("Campo obrigatório")
});

export const AddFileModal = ({
  addFile,
  editFile,
  hideModal,
  selectedFile
}: AddFileModalProps) => {
  const [executing, setExecuting] = useState(false);
  const [fileUploaded, setFileUploaded] = useState<File>();

  // Initial values
  const initialValues = selectedFile
    ? selectedFile.file
    : {
        url: "",
        description: "",
        createdAt: "",
        updatedAt: "",
        orderNum: 0
      };

  async function submitFile(values: VehicleFile) {
    setExecuting(true);

    if (selectedFile) {
      editFile({
        file: values,
        fileIdx: selectedFile.fileIdx
      });
      setExecuting(false);
    } else {
      if (fileUploaded !== undefined) {
        const newFile = addFile(values, fileUploaded);
        if ((await newFile).uuid) {
          setExecuting(false);
        }
      }
    }
  }

  return (
    <ReactModal
      shouldCloseOnOverlayClick={true}
      style={{
        overlay: { zIndex: 1000 },
        content: { width: 500, margin: "auto", height: "fit-content" }
      }}
      isOpen={true}
    >
      <Column>
        <Row>
          <StyledTitle>Novo ficheiro</StyledTitle>
          <CloseIcon
            onClick={() => hideModal()}
            style={{
              marginLeft: "auto",
              width: 18,
              height: 18,
              color: Colors["gold-pmauto"]
            }}
          />
        </Row>
        <Formik
          initialValues={initialValues}
          onSubmit={submitFile}
          validationSchema={formikValidation}
          validateOnChange={false}
        >
          {({ handleChange, handleSubmit, errors, values, setFieldValue }) => {
            return (
              <StyledColumn>
                <TextInput
                  value={values.description}
                  onChange={handleChange}
                  name="description"
                  title="Descrição"
                />
                {fileUploaded || values.url ? (
                  <FileUploadedContainer>
                    <StyledText>Ficheiro adicionado</StyledText>
                    <StyledTextFile>
                      {fileUploaded ? fileUploaded.name : values.url}
                    </StyledTextFile>
                  </FileUploadedContainer>
                ) : (
                  <UploadFile
                    handleUpload={(file) => {
                      setFileUploaded(file);
                    }}
                  />
                )}
                <PrimaryButton
                  label={
                    executing ? "A carregar ficheiro..." : "Guardar ficheiro"
                  }
                  type="button"
                  redTheme={true}
                  disabled={
                    executing ||
                    values.description.length === 0 ||
                    (!fileUploaded && values.url.length === 0)
                  }
                  onClick={() => handleSubmit()}
                />
              </StyledColumn>
            );
          }}
        </Formik>
      </Column>
    </ReactModal>
  );
};

const StyledTitle = styled("h1")`
  ${Typography.H2_Regular_Left}
`;

const FileUploadedContainer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
`;

const StyledText = styled("span")`
  $${Typography["Body_Text_#2_Low_Contrast_Left"]};
  font-weight: bold;
`;

const StyledTextFile = styled("span")`
  $${Typography["Body_Text_#2_Low_Contrast_Left"]};
  color: #44566c; 
  font-style: italic;
`;

const StyledColumn = styled(Column as any)`
  padding: 24px 40px;

  > div {
    margin-bottom: 24px;
  }
`;
