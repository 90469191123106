import React from "react";
import { VehiclePhoto } from "../../shared/shared.models";
import { Row, Column } from "../../shared/shared.styled";
import styled from "styled-components";
import { CloseOutlined } from "@material-ui/icons";

interface FullSizeGalleryProps {
  photos: VehiclePhoto[];
  currentPhoto: VehiclePhoto;
  closeOverlay: () => void;
}

export const FullSizeGallery = ({
  photos,
  currentPhoto,
  closeOverlay
}: FullSizeGalleryProps) => {
  const [selectedPhotoIdx, setSelectedPhotoIdx] = React.useState(
    photos.findIndex((p) => p.uuid === currentPhoto.uuid)
  );

  const onKeyboardClick = React.useCallback(
    (evt: KeyboardEvent) => {
      if (evt.keyCode === 37) {
        // Left arrow
        setSelectedPhotoIdx((idx) => {
          if (idx === 0) {
            return photos.length - 1;
          } else {
            return idx - 1;
          }
        });
      } else if (evt.keyCode === 39) {
        // Right arrow
        setSelectedPhotoIdx((idx) => {
          if (idx === photos.length - 1) {
            return 0;
          } else {
            return idx + 1;
          }
        });
      } else if (evt.keyCode === 27) {
        // Esc key
        closeOverlay();
      }
    },
    [photos, closeOverlay]
  );

  React.useEffect(() => {
    document.addEventListener("keydown", onKeyboardClick);

    return () => document.removeEventListener("keydown", onKeyboardClick);
  }, [onKeyboardClick]);

  return (
    <Overlay onClick={closeOverlay}>
      <Row
        style={{
          marginBottom: 40,
          width: "100%",
          paddingRight: 100,
          justifyContent: "flex-end"
        }}
      >
        <CloseOutlined style={{ color: "white", fontSize: 40 }} />
      </Row>
      <FullScreenImage
        onClick={(e) => {
          e.stopPropagation();
        }}
        src={photos[selectedPhotoIdx].url}
        alt="full screen img"
      />
      <Row
        style={{
          marginTop: 24,
          overflowX: "auto",
          width: "70%",
          height: 150
        }}
      >
        {photos.map((p, idx) => (
          <PhotoThumbnail
            key={p.uuid}
            onClick={(e) => {
              e.stopPropagation();
              setSelectedPhotoIdx(idx);
            }}
            isSelected={idx === selectedPhotoIdx}
            src={p.url}
          />
        ))}
      </Row>
    </Overlay>
  );
};

const FullScreenImage = styled("img")`
  height: 60%;
  width: auto;
`;

const Overlay = styled(Column as any)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.95);
  align-items: center;
  justify-content: center;
  z-index: 20;
`;

const PhotoThumbnail = styled("img")<{ isSelected: boolean }>(
  (props) => `
  width: 120px;
  height: 120px;
  margin-right: 24px;
  cursor: pointer;
  border-radius: 4px;
  ${props.isSelected ? "" : "opacity: 0.4"}
`
);
