import React from "react";
import styled from "styled-components";
import { Typography, Colors } from "../../configs/styled.config";
import PlusIcon from "../../assets/icons/plus.icon";
import { Column } from "../../shared/shared.styled";

interface UploadFileProps {
  handleUpload: (file: File) => void;
}

const UploadFile = ({ handleUpload }: UploadFileProps) => {
  //const alert = useAlert();
  /* 
    We assume that we can only upload files after the vehicle is created.
    With this assumption, it's easier for us because we can just upload the files directly after the user selects them.
    This means we can remove complex logic when dealing with files/images
  */

  const inputRef = React.useRef<HTMLInputElement>(null);

  return (
    <Column style={{ marginTop: 24 }}>
      <UploadFilesContainer htmlFor="upload-file">
        <input
          ref={inputRef}
          accept=".pdf,image/*"
          id="upload-file"
          onChange={(e) => {
            console.log(e.target);
            if (e.target.files && e.target.files.length > 0)
              handleUpload(e.target.files[0]);
          }}
          type="file"
          style={{ display: "none" }}
          multiple={false}
        />
        <div>
          <IconContainer>
            <PlusIcon />
          </IconContainer>
          <UploadFilesPlaceholder>Escolher ficheiro</UploadFilesPlaceholder>
        </div>
      </UploadFilesContainer>
    </Column>
  );
};

export default UploadFile;

const UploadFilesContainer = styled("label")`
  border: dashed 1px #979797;
  background-color: #f8f8f8;
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const IconContainer = styled("div")`
  width: 40px;
  height: 40px;
  margin-bottom: 24px;

  stroke: ${Colors["gold-pmauto"]};
  fill: ${Colors["gold-pmauto"]};
`;

const UploadFilesPlaceholder = styled("div")`
  ${Typography["Body_Text_#2_High_Contrast_Center"]};
`;
