import React, { InputHTMLAttributes } from "react";
import styled from "styled-components";
import { Typography, Colors } from "../../configs/styled.config";
import SearchIcon from "../../assets/icons/search.icon";
import { Row } from "../shared.styled";

interface SearchBarProps extends InputHTMLAttributes<HTMLInputElement> {
  handleChange: (value: string) => void;
  value: string;
}

const SearchBar = ({ handleChange, value, ...rest }: SearchBarProps) => {
  return (
    <Container value={!!value}>
      <IconContainer>
        <SearchIcon />
      </IconContainer>
      <StyledInput
        value={value || ""}
        {...rest}
        onChange={(evt) => handleChange(evt.target.value)}
      />
    </Container>
  );
};

export default SearchBar;

const Container = styled(Row as any)<{ value: boolean }>`
  width: 100%;
  height: 36px;
  border-radius: 22px;
  border: solid 1px
    ${(props) => (props.value ? Colors.slate : Colors["light-grey-blue"])};
  min-width: 250px;
  padding: 8px 12px;

  :disabled {
    background-color: rgba(216, 216, 216, 0.2);
  }
  :hover {
    border: solid 1px ${Colors.slate};
  }

  :focus {
    border: solid 1px ${Colors.slate};
  }
`;

const IconContainer = styled("div")`
  width: 18px;
  height: 18px;

  margin-left: 4px;
  margin-right: 8px;
`;

const StyledInput = styled("input")`
  ${Typography["Body_Text_#2_Regular_Left"]};
  outline: none;
  border: 0;

  ::placeholder {
    ${Typography["Body_Text_#2_Low_Contrast_Left"]};
    color: #8996a6;
    font-size: 16px;
  }
`;
