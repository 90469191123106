import React from "react";
import styled from "styled-components";
import { TableHeaders } from "./table-headers.component";
import { TableRow } from "./table-row.component";
import { Column } from "../../shared.styled";
import { SortOrderEnum } from "../../sort.utils";
import DotLoader from "react-spinners/DotLoader";
import { Colors, Typography } from "../../../configs/styled.config";
import { VisibilityStatusEnum } from "../../shared.enums";

export interface ColumnProps {
  label: string;
  key: string;
  ratio: number;
  visibility?: VisibilityStatusEnum;
  renderFunction?: (value: any) => React.ReactNode;
  onClick?: (header: string, order: SortOrderEnum) => void;
  alignment?: "flex-start" | "center" | "flex-end";
}

export interface TableValue extends Object {
  [key: string]: any;
}

interface TableProps<T> {
  columns: ColumnProps[];
  values: T[];
  rowStyles?: (value: T) => React.CSSProperties;
  onRowClick?: (value: T) => void;
  isLoadingData?: boolean;
  standExistOnly?: boolean;
  vehiclesExists?: boolean;
  contactsExists?: boolean;
}

export const Table = <T extends TableValue>({
  columns,
  rowStyles,
  onRowClick,
  values,
  isLoadingData = false,
  standExistOnly,
  vehiclesExists,
  contactsExists
}: TableProps<T>) => {
  return (
    <Container>
      <TableHeaders columns={columns} />
      {isLoadingData ? (
        <LoaderContainer>
          <DotLoader color={Colors["gold-pmauto"]} size={100} />
        </LoaderContainer>
      ) : values.length === 0 ? (
        <StyledNoVehicles>
          {contactsExists === false
            ? "Não existem contatos com estes filtros"
            : standExistOnly
            ? vehiclesExists
              ? "Não existem veículos com estes filtros"
              : "Não tem nenhum veículo criado"
            : "Nenhum stand associado. Por favor contacte-nos"}
        </StyledNoVehicles>
      ) : (
        values.map((entity, idx) => (
          <TableRow
            rowStyles={rowStyles}
            key={`row-${idx}`}
            onClick={onRowClick}
            columns={columns}
            entity={entity}
          />
        ))
      )}
    </Container>
  );
};

const Container = styled(Column as any)``;

const LoaderContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 200px;
`;

const StyledNoVehicles = styled("div")`
  ${Typography.H1_Regular_Center};
  font-size: 20px;
`;
