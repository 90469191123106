import { produce } from 'immer'
import {
    LoggedUser,
    Vehicle,
    CustomNotification,
    VehiclePhoto,
    Category,
    VehicleVideo,
    Stand,
    Company,
    Financial,
    CompanyFinancial,
    Contact,
    AnalyticCharts,
    Supplier,
    AnalyticValues,
    VehicleFile,
} from '../shared/shared.models'
import { PayloadAction } from '../shared/shared.interface'
import { Sale } from '../sales/sales.interfaces'
export const AdminActions = {
    types: {
        LOGIN_SUCCESS: 'LOGIN_SUCCESS',
        LOGOUT: 'LOGOUT',
        GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
        GET_VEHICLES_SUCCESS: 'GET_VEHICLES_SUCCESS',
        CREATE_VEHICLE_SUCCESS: 'CREATE_VEHICLE_SUCCESS',
        UPDATE_VEHICLE_SUCCESS: 'UPDATE_VEHICLE_SUCCESS',
        UPDATE_VEHICLE_PHOTOS: 'UPDATE_VEHICLE_PHOTOS',
        GET_NOTIFICATIONS_SUCCESS: 'GET_NOTIFICATIONS_SUCCESS',
        UPDATE_NOTIFICATION: 'UPDATE_NOTIFICATION',
        GET_CATEGORIES_SUCCESS: 'GET_CATEGORIES_SUCCESS',
        GET_COST_TYPES_SUCCESS: 'GET_COST_TYPES_SUCCESS',
        DELETE_NOTIFICATION_SUCCESS: 'DELETE_NOTIFICATION_SUCCESS',
        DELETE_ALL_NOTIFICATION_SUCCESS: 'DELETE_ALL_NOTIFICATION_SUCCESS',
        UPDATE_SALE_SUCCESS: 'UPDATE_SALE_SUCCESS',
        UPDATE_VEHICLE_VIDEO: 'UPDATE_VEHICLE_VIDEO',
        GET_STANDS_SUCCESS: 'GET_STANDS_SUCCESS',
        CREATE_STAND_SUCCESS: 'CREATE_STAND_SUCCESS',
        UPDATE_STAND_SUCCESS: 'UPDATE_STAND_SUCCESS',
        DEACTIVATE_STAND_SUCCESS: 'DEACTIVATE_STAND_SUCCESS',
        GET_COMPANIES_SUCCESS: 'GET_COMPANIES_SUCCESS',
        CREATE_COMPANY_SUCCESS: 'CREATE_COMPANY_SUCCESS',
        UPDATE_COMPANY_SUCCESS: 'UPDATE_COMPANY_SUCCESS',
        DELETE_COMPANY_SUCCESS: 'DELETE_COMPANY_SUCCESS',
        UPDATE_COMPANY_LOGO: 'UPDATE_COMPANY_LOGO',
        CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
        UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
        DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
        ACTIVATE_USER_SUCCESS: 'ACTIVATE_USER_SUCCESS',
        DEACTIVATE_USER_SUCCESS: 'DEACTIVATE_USER_SUCCESS',
        GET_FINANCIALS_SUCCESS: 'GET_FINANCIALS_SUCCESS',
        CREATE_FINANCIAL_SUCCESS: 'CREATE_FINANCIAL_SUCCESS',
        UPDATE_FINANCIAL_SUCCESS: 'UPDATE_FINANCIAL_SUCCESS',
        DELETE_FINANCIAL_SUCCESS: 'DELETE_FINANCIAL_SUCCESS',
        GET_VEHICLES_STAND_SUCCESS: 'GET_VEHICLES_STAND_SUCCESS',
        GET_ANALYSIS_SUCCESS: 'GET_ANALYSIS_SUCCESS',
        GET_ANALYTIC_CONTACT_CHARTS: 'GET_ANALYTIC_CONTACT_CHARTS',
        GET_CONTACTS_SUCCESS: 'GET_CONTACTS_SUCCESS',
        GET_CONTACT_SUCCESS: 'GET_CONTACT_SUCCESS',
        UPDATE_CONTACT_SUCCESS: 'UPDATE_CONTACT_SUCCESS',
        CREATE_CONTACT_SUCCESS: 'CREATE_CONTACT_SUCCESS',
        PATCH_CONTACT_SUCCESS: 'PATCH_CONTACT_SUCCESS',
        DELETE_CONTACT_SUCCESS: 'DELETE_CONTACT_SUCCESS',
        GET_PDF_SALE: 'GET_PDF_SALE',
        GET_PDF_CIRCULATION_AUTHORIZATION: 'GET_PDF_CIRCULATION_AUTHORIZATION',
        GET_PDF_CONTRACT: 'GET_PDF_CONTRACT',
        GET_PDF_WARRANTY: 'GET_PDF_WARRANTY',
        GET_PDF_MATERIAL: 'GET_PDF_MATERIAL',
        GET_PDF_FINES: 'GET_PDF_FINES',
        GET_PDF_PRIVACY: 'GET_PDF_PRIVACY',
        GET_PDF_RETAKE: 'GET_PDF_RETAKE',
        CREATE_SUPPLIER_SUCCESS: 'CREATE_SUPPLIER_SUCCESS',
        GET_SUPPLIER_SUCCESS: 'GET_SUPPLIER_SUCCESS',
        UPDATE_SUPPLIER_SUCCESS: 'UPDATE_SUPPLIER_SUCCESS',
        DELETE_SUPPLIER_SUCCESS: 'DELETE_SUPPLIER_SUCCESS',
        GET_CSV_STOCK: 'GET_CSV_STOCK',
        GET_CSV_SALES: 'GET_CSV_SALES',
        GET_CSV_CONTACTS: 'GET_CSV_CONTACTS',
        UPDATE_VEHICLE_FILES: 'UPDATE_VEHICLE_FILES',
        CREATE_VEHICLE_FILE: 'CREATE_VEHICLE_FILE'
    },
    methods: {
        loginSuccessAction: (
            user: LoggedUser,
            token: string,
            company: Company
        ) => ({
            type: AdminActions.types.LOGIN_SUCCESS,
            payload: {
                user,
                token,
                company,
            },
        }),
        logoutAction: () => ({
            type: AdminActions.types.LOGOUT,
        }),
        getUsersSuccessAction: (users: LoggedUser[]) => ({
            type: AdminActions.types.GET_USERS_SUCCESS,
            payload: {
                users,
            },
        }),
        getVehiclesSuccessAction: (vehicles: Vehicle[]) => ({
            type: AdminActions.types.GET_VEHICLES_SUCCESS,
            payload: {
                vehicles,
            },
        }),
        deleteAllNotificationsSuccess: () => ({
            type: AdminActions.types.DELETE_ALL_NOTIFICATION_SUCCESS,
        }),
        createVehicleSuccessAction: (vehicle: Vehicle) => ({
            type: AdminActions.types.CREATE_VEHICLE_SUCCESS,
            payload: {
                vehicle,
            },
        }),
        updateVehicleSuccessAction: (vehicle: Vehicle) => ({
            type: AdminActions.types.UPDATE_VEHICLE_SUCCESS,
            payload: {
                vehicle,
            },
        }),
        updateVehiclePhotosSuccessAction: (
            vehicleId: string,
            photos: Array<VehiclePhoto>
        ) => ({
            type: AdminActions.types.UPDATE_VEHICLE_PHOTOS,
            payload: {
                vehicleId,
                photos,
            },
        }),
        updateVehicleFileSuccessAction: (
            vehicleId: string,
            file: VehicleFile
        ) => ({
            type: AdminActions.types.UPDATE_VEHICLE_FILES,
            payload: {
                vehicleId,
                file,
            },
        }),
        getNotificationsSuccessAction: (
            notifications: CustomNotification[]
        ) => ({
            type: AdminActions.types.GET_NOTIFICATIONS_SUCCESS,
            payload: {
                notifications,
            },
        }),
        updateNotificationAction: (
            notificationId: string,
            newValue: boolean
        ) => ({
            type: AdminActions.types.UPDATE_NOTIFICATION,
            payload: {
                notificationId,
                newValue,
            },
        }),
        getCategoriesSuccessAction: (categories: Category[]) => ({
            type: AdminActions.types.GET_CATEGORIES_SUCCESS,
            payload: {
                categories,
            },
        }),
        getCostTypesSuccessAction: (costTypes: string[]) => ({
            type: AdminActions.types.GET_COST_TYPES_SUCCESS,
            payload: {
                costTypes,
            },
        }),
        deleteNotificationSuccessAction: (notificationId: string) => ({
            type: AdminActions.types.DELETE_NOTIFICATION_SUCCESS,
            payload: {
                notificationId,
            },
        }),
        updateSaleSuccessAction: (sale: Sale) => ({
            type: AdminActions.types.UPDATE_SALE_SUCCESS,
            payload: {
                sale,
            },
        }),
        updateVehicleVideoSuccessAction: (
            vehicleId: string,
            video: VehicleVideo
        ) => ({
            type: AdminActions.types.UPDATE_VEHICLE_VIDEO,
            payload: {
                vehicleId,
                video,
            },
        }),
        getStandsSuccessAction: (stands: Stand[]) => ({
            type: AdminActions.types.GET_STANDS_SUCCESS,
            payload: {
                stands,
            },
        }),
        createStandSuccessAction: (stand: Stand) => ({
            type: AdminActions.types.CREATE_STAND_SUCCESS,
            payload: {
                stand,
            },
        }),
        createVehicleFileSuccessAction: (file: VehicleFile) => ({
            type: AdminActions.types.CREATE_VEHICLE_FILE,
            payload: {
                file
            }
        }),
        updateStandSuccessAction: (stand: Stand) => ({
            type: AdminActions.types.UPDATE_STAND_SUCCESS,
            payload: {
                stand,
            },
        }),
        deactivateStandSuccessAction: (stand: Stand) => ({
            type: AdminActions.types.DEACTIVATE_STAND_SUCCESS,
            payload: {
                stand,
            },
        }),
        getCompaniesSuccessAction: (companies: Company[]) => ({
            type: AdminActions.types.GET_COMPANIES_SUCCESS,
            payload: {
                companies,
            },
        }),
        createCompanySuccessAction: (company: Company) => ({
            type: AdminActions.types.CREATE_COMPANY_SUCCESS,
            payload: {
                company,
            },
        }),
        updateCompanySuccessAction: (company: Company) => ({
            type: AdminActions.types.UPDATE_COMPANY_SUCCESS,
            payload: {
                company,
            },
        }),
        deleteCompanySuccessAction: (companyId: string) => ({
            type: AdminActions.types.DELETE_COMPANY_SUCCESS,
            payload: {
                companyId,
            },
        }),
        updateLogoSuccessAction: (companyId: string, photo: string) => ({
            type: AdminActions.types.UPDATE_COMPANY_LOGO,
            payload: {
                companyId,
                photo,
            },
        }),
        createUserSuccessAction: (user: LoggedUser) => ({
            type: AdminActions.types.CREATE_USER_SUCCESS,
            payload: {
                user,
            },
        }),
        updateUserSuccessAction: (user: LoggedUser) => ({
            type: AdminActions.types.UPDATE_USER_SUCCESS,
            payload: {
                user,
            },
        }),
        activateUserSuccessAction: (user: LoggedUser) => ({
            type: AdminActions.types.ACTIVATE_USER_SUCCESS,
            payload: {
                user,
            },
        }),
        deactivateUserSuccessAction: (user: LoggedUser) => ({
            type: AdminActions.types.DEACTIVATE_USER_SUCCESS,
            payload: {
                user,
            },
        }),
        getFinancialsSuccessAction: (financials: Financial[]) => ({
            type: AdminActions.types.GET_FINANCIALS_SUCCESS,
            payload: {
                financials,
            },
        }),
        createFinancialSuccessAction: (financial: Financial) => ({
            type: AdminActions.types.CREATE_FINANCIAL_SUCCESS,
            payload: {
                financial,
            },
        }),
        updateFinancialSuccessAction: (financial: Financial) => ({
            type: AdminActions.types.UPDATE_FINANCIAL_SUCCESS,
            payload: {
                financial,
            },
        }),
        deleteFinancialSuccessAction: (financial: Financial) => ({
            type: AdminActions.types.DELETE_FINANCIAL_SUCCESS,
            payload: {
                financial,
            },
        }),
        getVehiclesStandSuccessAction: (vehicles: Vehicle[]) => ({
            type: AdminActions.types.GET_VEHICLES_STAND_SUCCESS,
            payload: {
                vehicles,
            },
        }),
        getAnalysisSuccessAction: (analysis: AnalyticValues) => ({
            type: AdminActions.types.GET_ANALYSIS_SUCCESS,
            payload: {
                analysis,
            },
        }),
        getContactChartDataSuccessAction: (results: AnalyticCharts[]) => ({
            type: AdminActions.types.GET_ANALYTIC_CONTACT_CHARTS,
            payload: {
                results,
            },
        }),
        getContactsSuccessAction: (contacts: Contact[]) => ({
            type: AdminActions.types.GET_CONTACTS_SUCCESS,
            payload: {
                contacts,
            },
        }),
        createContactSuccessAction: (contact: Contact) => ({
            type: AdminActions.types.CREATE_CONTACT_SUCCESS,
            payload: {
                contact,
            },
        }),
        getContactSuccessAction: (contact: Contact) => ({
            type: AdminActions.types.GET_CONTACT_SUCCESS,
            payload: {
                contact,
            },
        }),
        updateContactSuccessAction: (contact: Contact) => ({
            type: AdminActions.types.UPDATE_CONTACT_SUCCESS,
            payload: {
                contact,
            },
        }),
        patchContactSuccessAction: (contact: Contact) => ({
            type: AdminActions.types.PATCH_CONTACT_SUCCESS,
            payload: {
                contact,
            },
        }),
        deleteContactSuccessAction: (contactId: string) => ({
            type: AdminActions.types.DELETE_CONTACT_SUCCESS,
            payload: {
                contactId,
            },
        }),
        getPDFSale: (blobData: Blob) => ({
            type: AdminActions.types.GET_PDF_SALE,
            payload: {
                blobData,
            },
        }),
        getPDFCirculationAuthorization: (blobData: Blob) => ({
            type: AdminActions.types.GET_PDF_CIRCULATION_AUTHORIZATION,
            payload: {
                blobData,
            },
        }),
        getPDFContract: (blobData: Blob) => ({
            type: AdminActions.types.GET_PDF_CONTRACT,
            payload: {
                blobData,
            },
        }),
        getPDFWarranty: (blobData: Blob) => ({
            type: AdminActions.types.GET_PDF_WARRANTY,
            payload: {
                blobData,
            },
        }),
        getPDFMaterial: (blobData: Blob) => ({
            type: AdminActions.types.GET_PDF_MATERIAL,
            payload: {
                blobData,
            },
        }),
        getPDFFines: (blobData: Blob) => ({
            type: AdminActions.types.GET_PDF_FINES,
            payload: {
                blobData,
            },
        }),
        getPDFPrivacy: (blobData: Blob) => ({
            type: AdminActions.types.GET_PDF_PRIVACY,
            payload: {
                blobData,
            },
        }),
        getPDFRetake: (blobData: Blob) => ({
            type: AdminActions.types.GET_PDF_RETAKE,
            payload: {
                blobData,
            },
        }),
        createSupplierSuccessAction: (supplier: Supplier) => ({
            type: AdminActions.types.CREATE_SUPPLIER_SUCCESS,
            payload: {
                supplier,
            },
        }),
        getSupplierSuccessAction: (supplier: Supplier) => ({
            type: AdminActions.types.GET_SUPPLIER_SUCCESS,
            payload: {
                supplier,
            },
        }),
        updateSupplierSuccessAction: (supplier: Supplier) => ({
            type: AdminActions.types.UPDATE_SUPPLIER_SUCCESS,
            payload: {
                supplier,
            },
        }),
        deleteSupplierSuccessAction: (supplier: Supplier) => ({
            type: AdminActions.types.DELETE_SUPPLIER_SUCCESS,
            payload: {
                supplier,
            },
        }),
        getCSVStock: (blobData: Blob) => ({
            type: AdminActions.types.GET_CSV_STOCK,
            payload: {
                blobData,
            },
        }),
        getCSVSales: (blobData: Blob) => ({
            type: AdminActions.types.GET_CSV_SALES,
            payload: {
                blobData,
            },
        }),
        getCSVContacts: (blobData: Blob) => ({
            type: AdminActions.types.GET_CSV_CONTACTS,
            payload: {
                blobData,
            },
        }),
    },
}

export interface AdminReducerInterface {
    user?: LoggedUser
    token?: string
    vehicles: Vehicle[]
    categories: Category[]
    users: LoggedUser[]
    costTypes: string[]
    notifications: CustomNotification[]
    stands: Stand[]
    companies: Company[]
    company?: Company
    financials?: CompanyFinancial[]
}

const initialState: AdminReducerInterface = {
    user: undefined,
    vehicles: [],
    categories: [],
    users: [],
    costTypes: [],
    notifications: [],
    stands: [],
    companies: [],
    company: undefined,
    financials: [],
}

export const adminReducer = produce(
    (draft: AdminReducerInterface, action: PayloadAction<any>) => {
        switch (action.type) {
            case AdminActions.types.LOGIN_SUCCESS: {
                const { user, token, company } = action.payload

                // Lets remove the notifications key from the user since this is re-rendering everything in our app
                const notifications = user.notifications
                delete user.notifications
                draft.notifications = notifications
                draft.user = user
                draft.token = token
                draft.company = company ? company : {}
                if (company) draft.financials = company.financials
                const financials = company ? company.financials : []
                if (company && company.financials) delete company.financials

                localStorage.setItem('token', token)
                localStorage.setItem('user', JSON.stringify(user))
                localStorage.setItem('company', JSON.stringify(company))
                localStorage.setItem('financials', JSON.stringify(financials))

                return
            }
            case AdminActions.types.LOGOUT:
                return initialState
            case AdminActions.types.GET_USERS_SUCCESS:
                const { users } = action.payload
                draft.users = users
                return
            case AdminActions.types.GET_VEHICLES_SUCCESS:
                draft.vehicles = action.payload.vehicles
                localStorage.setItem(
                    'vehicles',
                    JSON.stringify(action.payload.vehicles)
                )
                return
            case AdminActions.types.CREATE_VEHICLE_SUCCESS:
                const { vehicle } = action.payload
                draft.vehicles.push(vehicle)
                return
            case AdminActions.types.UPDATE_VEHICLE_SUCCESS: {
                const { vehicle } = action.payload
                const vehicleIdx = draft.vehicles.findIndex(
                    v => v.uuid === vehicle.uuid
                )
                if (vehicleIdx > -1) {
                    draft.vehicles.splice(vehicleIdx, 1, vehicle)
                } else {
                    draft.vehicles.push(vehicle)
                }
                return
            }
            case AdminActions.types.UPDATE_VEHICLE_PHOTOS: {
                const { vehicleId, photos } = action.payload
                const vehicleIdx = draft.vehicles.findIndex(
                    v => v.uuid === vehicleId
                )
                if (vehicleIdx > -1) {
                    draft.vehicles[vehicleIdx].photos = photos
                }
                return
            }
            case AdminActions.types.GET_NOTIFICATIONS_SUCCESS:
                const { notifications } = action.payload
                draft.notifications = notifications

                return
            case AdminActions.types.UPDATE_NOTIFICATION:
                const { notificationId, newValue } = action.payload
                const notifIdx = draft.notifications.findIndex(
                    n => n.uuid === notificationId
                )
                if (notifIdx > -1) {
                    draft.notifications[notifIdx].isRead = newValue
                }
                return
            case AdminActions.types.GET_CATEGORIES_SUCCESS:
                draft.categories = action.payload.categories
                localStorage.setItem(
                    'categories',
                    JSON.stringify(draft.categories)
                )
                return
            case AdminActions.types.DELETE_NOTIFICATION_SUCCESS:
                draft.notifications = draft.notifications.filter(
                    n => n.notificationUuid !== action.payload.notificationId
                )
                return
            case AdminActions.types.DELETE_ALL_NOTIFICATION_SUCCESS:
                draft.notifications = []
                return
            case AdminActions.types.GET_COST_TYPES_SUCCESS:
                draft.costTypes = action.payload.costTypes
                return
            case AdminActions.types.UPDATE_SALE_SUCCESS: {
                const sale = action.payload.sale as Sale
                const vehicleId = sale.vehicleUuid
                const vehicle = draft.vehicles.find(v => v.uuid === vehicleId)
                if (vehicle) {
                    vehicle.sale = sale
                }
                return
            }
            case AdminActions.types.UPDATE_VEHICLE_VIDEO: {
                const { vehicleId, video } = action.payload
                const vehicleIdx = draft.vehicles.findIndex(
                    v => v.uuid === vehicleId
                )
                if (vehicleIdx > -1) {
                    draft.vehicles[vehicleIdx].video = video
                }
                return
            }
            case AdminActions.types.GET_STANDS_SUCCESS: {
                draft.stands = action.payload.stands
                localStorage.setItem('stands', JSON.stringify(draft.stands))
                return
            }
            case AdminActions.types.CREATE_STAND_SUCCESS: {
                const { stand } = action.payload
                draft.stands.push(stand)
                return
            }
            case AdminActions.types.DEACTIVATE_STAND_SUCCESS: {
                const { stand } = action.payload
                const standIdx = draft.stands.findIndex(
                    v => v.uuid === stand.uuid
                )
                if (standIdx > -1) {
                    draft.stands.splice(standIdx, 1, stand)
                }
                return
            }
            case AdminActions.types.UPDATE_STAND_SUCCESS: {
                const { stand } = action.payload
                const standIdx = draft.stands.findIndex(
                    v => v.uuid === stand.uuid
                )
                if (standIdx > -1) {
                    draft.stands.splice(standIdx, 1, stand)
                }
                return
            }
            case AdminActions.types.GET_COMPANIES_SUCCESS:
                draft.companies = action.payload.companies
                return
            case AdminActions.types.CREATE_COMPANY_SUCCESS: {
                const { company } = action.payload
                draft.companies.push(company)
                return
            }
            case AdminActions.types.UPDATE_COMPANY_SUCCESS: {
                const { company } = action.payload
                const standIdx = draft.companies.findIndex(
                    v => v.uuid === company.uuid
                )
                if (standIdx > -1) {
                    draft.companies.splice(standIdx, 1, company)
                }
                return
            }
            case AdminActions.types.DELETE_COMPANY_SUCCESS: {
                const { company } = action.payload
                const standIdx = draft.companies.findIndex(
                    v => v.uuid === company.uuid
                )
                if (standIdx > -1) {
                    draft.stands.splice(standIdx, 1, company)
                }
                return
            }
            case AdminActions.types.GET_FINANCIALS_SUCCESS: {
                draft.financials = action.payload.financials
                return
            }
            default:
                return draft
        }
    },
    initialState
)
