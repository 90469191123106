import React from "react";
import styled from "styled-components";
import { Row, Column, SmallSectionTitle } from "../../shared/shared.styled";
import { useFormikContext } from "formik";
import { Vehicle, VehicleVideo } from "../../shared/shared.models";
import { useModal } from "react-modal-hook";
import { ConfirmationModal } from "../../shared/components/modals/confirmation.modal";
import { AdminApi } from "../admin.api";
import { HelperMenuVideo } from "./helper-menu.component";
import { useAlert } from "react-alert";
import { useIsAdmin } from "../../shared/hooks/use-is-admin.hook";

interface VideoGalleryProps {
  inEdition?: boolean;
}

const VideoGallery = ({ inEdition = true }: VideoGalleryProps) => {
  // Hooks
  const {
    values: { video },
    setFieldValue
  } = useFormikContext<Vehicle>();

  const alert = useAlert();
  const isAdmin = useIsAdmin();

  // Local state
  const [currentVideo, setCurrentVideo] = React.useState<VehicleVideo | null>(
    null
  );
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const deleteVideo = React.useCallback(async () => {
    if (currentVideo) {
      try {
        await AdminApi.methods.deleteVideo(currentVideo.uuid);
        let newVideo = null;
        setFieldValue("video", newVideo);
        alert.success("Vídeo eliminado com sucesso");
      } catch (e) {
        alert.error("Erro ao eliminar o vídeo. Por favor tente mais tarde");
      }
    }
  }, [currentVideo, alert, setFieldValue]);

  const [showConfirmationModal, hideConfirmationModal] = useModal(
    () => (
      <ConfirmationModal
        title="Está prestes a eliminar um vídeo"
        description="Tem a certeza que quer eliminar o vídeo?"
        onCancel={hideConfirmationModal}
        onClick={deleteVideo}
      />
    ),
    [deleteVideo]
  );

  const resetState = () => {
    setAnchorEl(null);
    setCurrentVideo(null);
  };

  // Handlers
  const handleClick = (
    event: React.MouseEvent<HTMLVideoElement>,
    video: VehicleVideo
  ) => {
    if (anchorEl && anchorEl.id === event.currentTarget.id) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
    if (currentVideo && currentVideo.uuid === video.uuid) {
      setCurrentVideo(null);
    } else {
      setCurrentVideo(video);
    }
  };

  return video ? (
    <ImageGalleryContainer>
      <SmallSectionTitle style={{ marginBottom: 36 }}>
        Galeria de vídeo
      </SmallSectionTitle>
      <VideoRow>
        <VideoThumbnail
          key={`video-${video.uuid}`}
          onClick={
            !isAdmin || !inEdition
              ? () => {
                  setCurrentVideo(video);
                }
              : (e) => handleClick(e, video)
          }
          id={`video-${video.uuid}`}
          src={video.url}
        />
      </VideoRow>
      {currentVideo && anchorEl && (
        <HelperMenuVideo
          showConfirmationModal={showConfirmationModal}
          closePopper={resetState}
          video={currentVideo}
          anchorEl={anchorEl}
        />
      )}
    </ImageGalleryContainer>
  ) : (
    <NoVideo>Este carro ainda não tem vídeo</NoVideo>
  );
};

export default VideoGallery;

const ImageGalleryContainer = styled(Column as any)`
  justify-content: flex-end;
`;

const VideoRow = styled(Row as any)`
  flex: 1;
  flex-wrap: wrap;
`;
const VideoThumbnail = styled("video")`
  width: auto;
  height: 200px;
  margin-right: 24px;
  cursor: pointer;
  border-radius: 4px;
  border: 3px solid red;
`;

const NoVideo = styled("div")`
  font-family: Merriweather-Light;
  font-size: 16px;
`;
