import React from "react";
import styled from "styled-components";
import { Colors, Typography } from "../../configs/styled.config";
import { DropdownOption } from "../shared.interface";
import ChevronDownIcon from "../../assets/icons/chevron-down.icon";
import { FormikErrors } from "formik";

interface DropdownProps {
  options: DropdownOption[];
  selectValue: (val: DropdownOption) => void;
  clearValue?: () => void;
  value?: string;
  error?: string;
  placeholder: string;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  containerStyles?: React.CSSProperties;
  inEdition?: boolean;
}

const Dropdown = ({
  options,
  selectValue,
  error,
  value,
  placeholder,
  clearValue,
  label,
  disabled = false,
  required = false,
  containerStyles = {},
  inEdition = true
}: DropdownProps) => {
  const panelRef = React.useRef<HTMLDivElement>(null);
  const dropdownRef = React.useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = React.useState(false);

  const getLabel = () => {
    const option = options.find((o) => o.key === value);
    if (option) {
      return option.label;
    }
    return inEdition ? placeholder : "N/A";
  };

  const isValueSelected = (opt: DropdownOption) => {
    return opt.key === value;
  };

  const _handleOnChange = (opt: DropdownOption) => {
    selectValue(opt);
    setIsOpen(false);
  };

  const _handleClearValues = () => {
    if (clearValue) {
      clearValue();
    }
    setIsOpen(false);
  };

  const checkClickOutside = (e: any) => {
    if (
      panelRef.current &&
      !panelRef.current.contains(e.target) &&
      dropdownRef.current &&
      !dropdownRef.current.contains(e.target)
    ) {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener("mousedown", checkClickOutside);

    return () => window.removeEventListener("mousedown", checkClickOutside);
  });

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        ...containerStyles
      }}
    >
      {label ? <Label isRequired={required}>{label}</Label> : null}

      {inEdition ? (
        <DropdownContainer
          disabled={disabled}
          error={error}
          onClick={() => (disabled ? null : setIsOpen(!isOpen))}
          value={value}
          ref={dropdownRef}
        >
          <DropdownText value={value}>{getLabel()}</DropdownText>
          <IconContainer isOpen={isOpen}>
            <ChevronDownIcon />
          </IconContainer>
        </DropdownContainer>
      ) : (
        <DisplayDropdown>{getLabel()}</DisplayDropdown>
      )}
      {isOpen ? (
        <PanelContainer ref={panelRef} hasLabel={!!label}>
          {options.map((opt) => (
            <Option key={opt.key} onClick={() => _handleOnChange(opt)}>
              <OptionText isSelected={isValueSelected(opt)}>
                {opt.label}
              </OptionText>
            </Option>
          ))}
          {clearValue ? (
            <Option key="clear" onClick={_handleClearValues}>
              <OptionText isSelected={false}>Limpar valores</OptionText>
            </Option>
          ) : null}
        </PanelContainer>
      ) : null}
      {error && (
        <StyledError>
          {typeof error === "object" ? error[0] : error}
        </StyledError>
      )}
    </div>
  );
};

export default Dropdown;

interface DropdownContainerProps {
  value?: string;
  error?: string | FormikErrors<any>;
  disabled?: boolean;
}

const DropdownContainer = styled("div")<DropdownContainerProps>`
  border-radius: 6px;
  border: solid 1px
    ${(props) =>
      props.value
        ? Colors.slate
        : props.error
        ? Colors["alive-red"]
        : Colors["light-grey-blue"]};
  width: 100%;
  height: 36px;
  position: relative;
  padding: 0px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  min-width: 250px;

  ${(props) =>
    props.disabled
      ? "background-color: rgba(216, 216, 216, 0.2)"
      : "background-color: white"}
`;

const DropdownText = styled("div")<{ value?: string }>`
  ${(props) =>
    props.value
      ? Typography["Body_Text_#2_Regular_Left"]
      : `
        ${Typography["Body_Text_#2_Low_Contrast_Left"]}
        color: #8996a6;
      `}
`;
/* FIXME: "top" is a magic number, we need to have a way to calculate this */

const PanelContainer = styled("div")<{ hasLabel: boolean }>`
  position: absolute;
  box-shadow: -1px 11px 19px -7px rgba(0, 0, 0, 0.35);
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  top: ${(props) => (props.hasLabel ? "73px" : "36px")};
  z-index: 10;
  max-height: 240px;
  overflow-y: auto;
`;

const Option = styled("div")`
  height: 30px;
  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  :hover {
    background-color: ${Colors["ice-blue"]};
  }
`;

const OptionText = styled("div")<{ isSelected: boolean }>`
  ${(props) =>
    props.isSelected
      ? Typography["Body_Text_#3_High Contrast_Left"]
      : Typography["Body_Text_#3_Regular_Left"]};
`;

const IconContainer = styled("div")<{ isOpen: boolean }>`
  margin-left: auto;
  width: 18px;
  stroke: #b0bbc7;
  ${(props) => (props.isOpen ? "transform: rotate(180deg);" : "")}
`;

const Label = styled("label")<{ isRequired: boolean }>`
  font-weight: bold;
  ${Typography["Body_Text_#1_Regular_Left"]};
  font-size: 18px;
  color: #44566c;
  margin-bottom: 8px;

  ${(props) =>
    props.isRequired
      ? `
   ::after {
     margin-left: 2px;
     content: '*';
     color: ${Colors["gold-pmauto"]};
   }
   `
      : ""}
`;

const StyledError = styled("span")`
  ${Typography.Tiny_Text_Regular_Left};
  color: ${Colors["alive-red"]};
  margin-top: 8px;
  font-size: 12px;
`;

const DisplayDropdown = styled("div")`
  height: 36px;
  padding: 8px 0px;
  ${Typography["Body_Text_#2_Regular_Left"]};
`;
