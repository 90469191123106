import React from "react";
import { ColumnProps, TableValue } from "./table.component";
import styled from "styled-components";
import { Row, RatioContainer } from "../../shared.styled";
import { Colors } from "../../../configs/styled.config";
import { useIsSuperAdmin } from "../../hooks/use-is-super-admin.hook";
import { HeaderProps } from "./table-headers.component";
import { useIsAdmin } from "../../hooks/use-is-admin.hook";

interface TableRowManageProps<T> {
  entity: T;
  columns: ColumnProps[];
  rowStyles?: (value: T) => React.CSSProperties;
  onClick?: (value: T) => void;
  adminAlso?: boolean;
}

export const TableRowManage = <T extends TableValue>({
  entity,
  columns,
  rowStyles,
  onClick,
  adminAlso = false
}: TableRowManageProps<T>) => {
  const isSuperAdmin = useIsSuperAdmin();
  const isAdmin = useIsAdmin();
  const renderCell = (column: ColumnProps) => {
    return (
      <Cell
        key={`cell-${column.key}`}
        ratio={column.ratio}
        alignment={column.alignment}
        id={`cell-${column.key}`}
      >
        {column.renderFunction ? (
          column.renderFunction(entity)
        ) : (
          <CellText>{!!entity[column.key] ? entity[column.key] : "-"}</CellText>
        )}
      </Cell>
    );
  };

  return (
    <Container
      style={rowStyles ? rowStyles(entity) : {}}
      onClick={() => (onClick ? onClick(entity) : null)}
    >
      {columns.map((column) => {
        return (isSuperAdmin || (adminAlso && isAdmin)) && renderCell(column);
      })}
    </Container>
  );
};

const Cell = styled(RatioContainer as any)<HeaderProps>`
  display: flex;
  justify-content: ${(props) =>
    props.alignment ? props.alignment : "flex-start"};
  font-family: Merriweather-Light;
  font-size: 16px;
  color: #4d5762;
  * {
    font-family: Merriweather-Light;
    font-size: 16px;
    color: #4d5762;
    ${(props) =>
      props.id === "cell-iteractions" &&
      "white-space: nowrap;text-overflow: ellipsis; overflow: hidden; max-width: 25vw;"}
  }
`;

const CellText = styled("span")`
  font-family: Merriweather-Light;
  font-size: 16px;
  color: #4d5762;
`;

const Container = styled(Row as any)`
  height: 10vh;
  border-radius: 10px;
  border: solid 1px #979797;
  background-color: ${Colors.white};
  cursor: pointer;
  margin-bottom: 16px;
  padding: 0 12px;

  :hover {
    box-shadow: -8px 12px 18px 0 rgba(25, 42, 70, 0.12);
  }
`;
