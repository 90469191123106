import React from "react";
import { FilterProps } from "../../shared/shared.interface";
import { Column, WrapRow, RatioContainer } from "../../shared/shared.styled";
import styled from "styled-components";
import SearchBar from "../../shared/components/search-bar.component";
import Checkbox from "../../shared/components/checkbox.component";
import Dropdown from "../../shared/components/dropdown.component";
import { DropdownUtils } from "../../shared/utils/dropdowns.utils";
import { useSelector } from "react-redux";
import { IStoreInterface } from "../../configs/store.config";
import TextInput from "../../shared/components/text-input.component";
import { useIsAdmin } from "../../shared/hooks/use-is-admin.hook";
import { VehicleFilters } from "./vehicle-search.component";
import SmallButton from "../../shared/components/small-button.component";
import CloseIcon from "../../assets/icons/close.icon";
import { Stand } from "../../shared/shared.models";
import { AdminApi } from "../admin.api";
import { useAlert } from "react-alert";

export const SoldVehiclesSearch = ({
  filters,
  updateFilter,
  clearFilter,
  clearAllFilters
}: FilterProps) => {
  const isAdmin = useIsAdmin();
  // Redux state
  const users = useSelector(
    (state: IStoreInterface) => state.adminReducer.users
  );
  const financials = useSelector(
    (state: IStoreInterface) => state.adminReducer.financials
  );

  const alert = useAlert();
  const [stands, setStands] = React.useState<Stand[]>([]);
  const { company } = useSelector(
    (state: IStoreInterface) => state.adminReducer
  );

  React.useEffect(() => {
    try {
      if (company) {
        AdminApi.methods.getStands(company.uuid).then(
          (res) => {
            setStands(res.data.stands);
          },
          (e) => {
            alert.error("Error fetching the stands list");
          }
        );
      }
    } catch (e) {
      alert.error("Erro ao listar os stands. Por favor tente mais tarde");
    }
  }, [alert, company]);

  React.useEffect(() => {
    // Parse URL parameters
    const queryParams = new URLSearchParams(window.location.search);

    // Iterate over each parameter and apply filters
    queryParams.forEach((value, key) => {
      updateFilter(key as VehicleFilters, value);
    });
  }, [updateFilter]);

  const handleFilterChange = (filterKey: any, filterValue: any) => {
    // Update filter in state
    updateFilter(filterKey, filterValue);

    // Construct new URL with filter parameter
    const queryParams = new URLSearchParams(window.location.search);
    if (filterValue?.length === 0) {
      queryParams.delete(filterKey);
    } else {
      queryParams.set(filterKey, filterValue);
    }

    // Update URL without reloading the page
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${queryParams.toString()}`
    );
  };

  return (
    <SearchContainer>
      <WrapRow>
        <RatioContainer ratio={2 / 10}>
          <SearchBar
            handleChange={(value) =>
              handleFilterChange(VehicleFilters.BRAND_OR_MODEL, value)
            }
            value={filters[VehicleFilters.BRAND_OR_MODEL]}
            placeholder="Marca ou Modelo"
          />
        </RatioContainer>
        <RatioContainer ratio={1 / 10}>
          <SearchBar
            handleChange={(value) =>
              handleFilterChange(VehicleFilters.LICENSE_PLATE, value)
            }
            value={filters[VehicleFilters.LICENSE_PLATE]}
            placeholder="Matrícula"
          />
        </RatioContainer>
        <RatioContainer ratio={1 / 10}>
          <TextInput
            onChange={(e) =>
              handleFilterChange(VehicleFilters.SALE_MONTH, e.target.value)
            }
            value={filters[VehicleFilters.SALE_MONTH] || ""}
            placeholder="Mês de venda"
            type="number"
          />
        </RatioContainer>
        <RatioContainer ratio={1 / 10}>
          <TextInput
            onChange={(e) =>
              handleFilterChange(VehicleFilters.SALE_YEAR, e.target.value)
            }
            value={filters[VehicleFilters.SALE_YEAR] || ""}
            placeholder="Ano de venda"
            type="number"
          />
        </RatioContainer>
        {isAdmin ? (
          <>
            <RatioContainer ratio={2 / 10}>
              <Checkbox
                label="Viaturas financiadas"
                value={filters[VehicleFilters.FINANCED_VEHICLE]}
                onChange={(e) =>
                  handleFilterChange(
                    VehicleFilters.FINANCED_VEHICLE,
                    e.target.checked || undefined
                  )
                }
              />
            </RatioContainer>
            <RatioContainer ratio={1 / 10}>
              <Dropdown
                selectValue={(val) =>
                  handleFilterChange(VehicleFilters.LOAN_COMPANY, val.key)
                }
                value={filters[VehicleFilters.LOAN_COMPANY]}
                clearValue={() =>
                  handleFilterChange(VehicleFilters.LOAN_COMPANY, "")
                }
                placeholder="Escolha a financeira"
                options={DropdownUtils.getLoanCompanyDropdownOptions(
                  financials ? financials : []
                )}
              />
            </RatioContainer>
            <RatioContainer ratio={1 / 10}>
              <Dropdown
                selectValue={(val) =>
                  handleFilterChange(VehicleFilters.SELLER, val.key)
                }
                value={filters[VehicleFilters.SELLER]}
                clearValue={() => handleFilterChange(VehicleFilters.SELLER, "")}
                placeholder="Escolha o vendedor"
                options={DropdownUtils.getUsersDropdownOptions(users)}
              />
            </RatioContainer>
            <RatioContainer ratio={1 / 10}>
              <Dropdown
                selectValue={(val) =>
                  handleFilterChange(VehicleFilters.STAND_LOCATION, val.key)
                }
                value={filters[VehicleFilters.STAND_LOCATION]}
                clearValue={() =>
                  handleFilterChange(VehicleFilters.STAND_LOCATION, "")
                }
                placeholder="Escolha o stand"
                options={DropdownUtils.getStandLocationDropdownOptions(stands)}
              />
            </RatioContainer>
          </>
        ) : null}
        <RatioContainer ratio={1.5 / 5}>
          {Object.keys(filters).length > 0 ? (
            <SmallButton
              iconComponent={<CloseIcon />}
              label="Limpar filtros"
              onClick={() => {
                clearAllFilters();
                window.history.replaceState(
                  {},
                  "",
                  `${window.location.pathname}`
                );
              }}
            />
          ) : null}
        </RatioContainer>
      </WrapRow>
    </SearchContainer>
  );
};

const SearchContainer = styled(Column as any)`
  ${WrapRow} {
    > div {
      margin-bottom: 36px;
    }
    > div:not(:last-child) {
      margin-right: 24px;
    }
  }
`;
