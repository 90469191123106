import React from "react";
import { VehicleFile } from "../../shared/shared.models";
import { Row, Column } from "../../shared/shared.styled";
import styled from "styled-components";
import { CloseOutlined } from "@material-ui/icons";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

interface FullSizeGalleryProps {
  file: VehicleFile;
  closeOverlay: () => void;
}

export const FullSizeFilesGallery = ({
  file,
  closeOverlay
}: FullSizeGalleryProps) => {
  const isImage = /\.(jpeg|jpg|gif|png)$/i.test(file.url);
  const isPDF = /\.pdf$/i.test(file.url);

  const onKeyboardClick = React.useCallback(
    (evt: KeyboardEvent) => {
      if (evt.keyCode === 27) {
        // Esc key
        closeOverlay();
      }
    },
    [file, closeOverlay]
  );

  React.useEffect(() => {
    document.addEventListener("keydown", onKeyboardClick);

    return () => document.removeEventListener("keydown", onKeyboardClick);
  }, [onKeyboardClick]);

  return (
    <Overlay onClick={closeOverlay}>
      <Row
        style={{
          marginBottom: 40,
          width: "100%",
          paddingRight: 100,
          justifyContent: "flex-end"
        }}
      >
        <CloseOutlined style={{ color: "white", fontSize: 40 }} />
      </Row>
      {isImage ? (
        <FullScreenImage
          onClick={(e) => {
            e.stopPropagation();
          }}
          src={file.url}
          alt="full screen img"
        />
      ) : isPDF ? (
        <Worker
          workerUrl={`https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js`}
        >
          <Viewer fileUrl={file.url} />
        </Worker>
      ) : null}
    </Overlay>
  );
};

const FullScreenImage = styled("img")`
  height: 60%;
  width: auto;
`;

const Overlay = styled(Column as any)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.95);
  align-items: center;
  justify-content: center;
  z-index: 20;
`;

const FileThumbnail = styled("img")<{ isSelected: boolean }>(
  (props) => `
  width: 120px;
  height: 120px;
  margin-right: 24px;
  cursor: pointer;
  border-radius: 4px;
  ${props.isSelected ? "" : "opacity: 0.4"}
`
);
