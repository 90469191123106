import React, { useState } from "react";
import ReactModal from "react-modal";
import {
  Column,
  Row,
  WrapRow,
  RatioContainer
} from "../../../shared/shared.styled";
import { Formik } from "formik";
import TextInput from "../../../shared/components/text-input.component";
import PrimaryButton from "../../../shared/components/button.component";
import * as Yup from "yup";
import styled from "styled-components";
import { Typography, Colors } from "../../../configs/styled.config";
import CloseIcon from "../../../assets/icons/close.icon";
import Dropdown from "../../../shared/components/dropdown.component";
import { DropdownUtils } from "../../../shared/utils/dropdowns.utils";
import { Supplier, SupplierForm } from "../../../shared/shared.models";
import { SelectedSupplier } from "../supplier-section.component";
import { useSelector } from "react-redux";
import { IStoreInterface } from "../../../configs/store.config";
import Checkbox from "../../../shared/components/checkbox.component";
import { useIsAdmin } from "../../../shared/hooks/use-is-admin.hook";
import { useIsWorkshop } from "../../../shared/hooks/use-is-workshop.hook";
import { AdminApi } from "../../admin.api";

interface AddSupplierModalProps {
  addSupplier: (values: Supplier) => void;
  editSupplier: (values: SelectedSupplier) => void;
  hideModal: () => void;
  selectedSupplier: SelectedSupplier | undefined;
}

const formikValidation = Yup.object().shape({
  name: Yup.string().required("Campo obrigatório")
});

export const AddSupplierModal = ({
  addSupplier,
  editSupplier,
  hideModal,
  selectedSupplier
}: AddSupplierModalProps) => {
  // Redux state
  const { company } = useSelector(
    (state: IStoreInterface) => state.adminReducer
  );

  //Hooks
  const isAdmin = useIsAdmin();
  const isWorkshop = useIsWorkshop();

  function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const [suppliers, setSuppliers] = React.useState<Supplier[]>([]);
  const [executing, setExecuting] = useState(false);
  const [isFrequent, setIsFrequent] = useState<boolean | undefined>(
    selectedSupplier?.supplier?.isFrequent ?? undefined
  );

  // Initial values
  const emptyValues = {
    uuid: undefined,
    companyUuid: company ? company?.uuid : "",
    isFrequent: false,
    name: "",
    county: "",
    district: "",
    email: undefined,
    nif: undefined,
    parish: "",
    phone: "",
    street: ""
  };
  const initialValues: Supplier = selectedSupplier
    ? selectedSupplier.supplier
    : emptyValues;

  async function submitSupplier(values: Supplier) {
    setExecuting(true);
    if (selectedSupplier) {
      editSupplier({
        supplier: values,
        supplierIdx: selectedSupplier.supplierIdx
      });
    } else {
      addSupplier(values);
    }
    await sleep(1000);
    setExecuting(false);
  }

  React.useEffect(() => {
    try {
      if (company) {
        AdminApi.methods.getSuppliers(company.uuid).then((res) => {
          setSuppliers(res.data.suppliers);
        });
      }
    } catch (e) {
      console.log("Erro ao listar os fornecedores. Por favor tente mais tarde");
    }
  }, [company]);

  return (
    <ReactModal
      shouldCloseOnOverlayClick={true}
      style={{
        overlay: { zIndex: 1000 },
        content: {
          width: 900,
          minHeight: 400,
          margin: "auto",
          height: "fit-content"
        }
      }}
      isOpen={true}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={submitSupplier}
        validationSchema={formikValidation}
        validateOnChange={false}
      >
        {({ handleChange, handleSubmit, errors, values, setFieldValue }) => {
          return (
            <Column>
              <Row>
                <StyledTitle>Detalhes do fornecedor</StyledTitle>
                <CloseIcon
                  onClick={() => hideModal()}
                  style={{
                    marginLeft: "auto",
                    width: 18,
                    height: 18,
                    color: Colors["gold-pmauto"]
                  }}
                />
              </Row>
              <Row>
                <RatioContainer ratio={1 / 8}>
                  <StyledSubtitle>É frequente?</StyledSubtitle>
                </RatioContainer>
                <RatioContainer ratio={1 / 8}>
                  <Checkbox
                    label="Sim"
                    checked={isFrequent === true}
                    onChange={(e) => {
                      if (!isFrequent) {
                        setIsFrequent(true);
                      } else {
                        setIsFrequent(undefined);
                      }
                    }}
                  />
                </RatioContainer>
                <RatioContainer ratio={1 / 8}>
                  <Checkbox
                    label="Não"
                    checked={isFrequent === false}
                    onChange={(e) => {
                      if (isFrequent || isFrequent === undefined) {
                        setIsFrequent(false);
                        (Object.keys(emptyValues) as Array<
                          keyof SupplierForm
                        >).forEach((key: keyof SupplierForm) => {
                          setFieldValue(key as string, emptyValues[key]);
                        });
                      } else if (!isFrequent) {
                        setIsFrequent(undefined);
                      }
                    }}
                  />
                </RatioContainer>
              </Row>
              {isFrequent === true ? (
                <StyledColumn>
                  <Row>
                    <Dropdown
                      containerStyles={{
                        maxWidth: 250,
                        marginBottom: 24
                      }}
                      inEdition={isAdmin || isWorkshop}
                      disabled={!isAdmin && !isWorkshop}
                      value={values.uuid ?? ""}
                      selectValue={(opt) => {
                        const chosenSupplier = suppliers.find(
                          (elem) => elem?.uuid === opt.key
                        );
                        if (chosenSupplier !== undefined) {
                          (Object.keys(chosenSupplier) as Array<
                            keyof Supplier
                          >).forEach((key: keyof Supplier) => {
                            if (key !== "numberVehicles") {
                              setFieldValue(key as string, chosenSupplier[key]);
                            }
                          });
                        }
                      }}
                      placeholder="Escolher fornecedor"
                      options={DropdownUtils.getSuppliersDropdownOptions(
                        suppliers?.filter((elem) => elem?.isFrequent)
                      )}
                    />
                  </Row>
                  <Row>
                    <PrimaryButton
                      label="Guardar fornecedor"
                      type="button"
                      redTheme={true}
                      onClick={() => handleSubmit()}
                      disabled={executing || false}
                    />
                  </Row>
                </StyledColumn>
              ) : (
                isFrequent === false && (
                  <StyledColumn>
                    <ResponsiveRow>
                      <Column style={{ width: "100%" }}>
                        <WrapRow
                          style={{
                            alignItems: "flex-start"
                          }}
                        >
                          <RatioContainer ratio={1 / 4}>
                            <TextInput
                              required={true}
                              title="Nome"
                              name="name"
                              placeholder="Ex: Fornecedor A"
                              onChange={handleChange}
                              value={values.name}
                            />
                          </RatioContainer>
                        </WrapRow>
                        <WrapRow
                          style={{
                            alignItems: "flex-start"
                          }}
                        >
                          <RatioContainer ratio={2 / 5}>
                            <TextInput
                              required={false}
                              placeholder="Ex: Rua Oliveira do Bairro"
                              name="street"
                              title="Rua"
                              onChange={handleChange}
                              value={values.street}
                            />
                          </RatioContainer>
                          <RatioContainer ratio={1 / 5}>
                            <TextInput
                              required={false}
                              title="Freguesia"
                              placeholder="Ex: Travanca"
                              name="parish"
                              onChange={handleChange}
                              value={values.parish}
                            />
                          </RatioContainer>

                          <RatioContainer ratio={1 / 5}>
                            <TextInput
                              required={false}
                              title="Concelho"
                              placeholder="Ex: Ovar"
                              name="county"
                              onChange={handleChange}
                              value={values.county}
                            />
                          </RatioContainer>

                          <RatioContainer ratio={1 / 5}>
                            <TextInput
                              required={false}
                              title="Distrito"
                              placeholder="Ex: Viseu"
                              name="district"
                              onChange={handleChange}
                              value={values.district}
                            />
                          </RatioContainer>
                        </WrapRow>
                        <WrapRow
                          style={{
                            alignItems: "flex-start"
                          }}
                        >
                          <RatioContainer ratio={1 / 3}>
                            <TextInput
                              required={false}
                              title="Telemóvel"
                              placeholder="Ex: 914232123"
                              name="phone"
                              maxLength={9}
                              onChange={handleChange}
                              value={values.phone}
                            />
                          </RatioContainer>
                          <RatioContainer ratio={1 / 3}>
                            <TextInput
                              required={false}
                              title="Email"
                              placeholder="Ex: ola@gmail.com"
                              name="email"
                              type="email"
                              onChange={handleChange}
                              value={values.email}
                            />
                          </RatioContainer>
                          <RatioContainer ratio={1 / 3}>
                            <TextInput
                              required={false}
                              title="NIF"
                              placeholder="Ex: 232222123"
                              name="nif"
                              type="number"
                              onChange={handleChange}
                              value={values.nif}
                            />
                          </RatioContainer>
                        </WrapRow>
                        <PrimaryButton
                          label="Guardar fornecedor"
                          type="button"
                          redTheme={true}
                          onClick={() => handleSubmit()}
                          disabled={executing || false}
                        />
                      </Column>
                    </ResponsiveRow>
                  </StyledColumn>
                )
              )}
            </Column>
          );
        }}
      </Formik>
    </ReactModal>
  );
};

const StyledTitle = styled("h1")`
  ${Typography.H2_Regular_Left}
`;

const StyledSubtitle = styled("h3")`
  ${Typography.H3_Regular_Left}
  display: flex;
  flex-direction: row;
  flex: none;
  ::after {
    margin-left: 2px;
    content: "*";
    color: ${Colors["gold-pmauto"]};
  }
`;

const StyledColumn = styled(Column as any)`
  padding: 24px 40px;

  > div {
    margin-bottom: 24px;
  }
`;

const ResponsiveRow = styled(Row as any)`
  justify-content: space-between;
  align-items: flex-start;

  @media screen and (max-width: 1025px) {
    flex-direction: column-reverse;
    justify-content: center;
    > ${Column} {
      width: 100% !important;
    }

    img {
      margin: auto;
      margin-bottom: 48px;
    }
  }
`;
