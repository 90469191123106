import React from "react";
import {
  Row,
  Column,
  WrapRow,
  RatioContainer
} from "../../shared/shared.styled";
import { useIsAdmin } from "../../shared/hooks/use-is-admin.hook";
import {
  AnalyticValues,
  AnalyticCharts,
  SimpleUser,
  Supplier
} from "../../shared/shared.models";
import { euroFormatter } from "../../shared/shared.utils";
import styled from "styled-components";
import { Typography, Colors } from "../../configs/styled.config";
import TranslationUtils from "../../shared/utils/translations.utils";
import { Tabs, AppBar, Tab } from "@material-ui/core";
import { TabPanel } from "../../shared/components/tabs/tabs-tab-panel.component";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line
} from "recharts";
import Checkbox from "../../shared/components/checkbox.component";
import Dropdown from "../../shared/components/dropdown.component";
import { DropdownUtils } from "../../shared/utils/dropdowns.utils";
import { useSelector } from "react-redux";
import { IStoreInterface } from "../../configs/store.config";
import { AdminApi } from "../admin.api";
import { useAlert } from "react-alert";
import CompanySuppliers from "./company-suppliers.component";

interface AnalyticValuesProps {
  analytics?: AnalyticValues;
  contactsChartData?: AnalyticCharts[];
  companyUsers: SimpleUser[];
  filterUser: string;
  setFilterUser: (value: string) => void;
  filterInitialDate: string;
  setFilterInitialDate: (value: string) => void;
  filterEndDate: string;
  setFilterEndDate: (value: string) => void;
  filterGroupBy: string;
  setFilterGroupBy: (value: string) => void;
}

export const Analytics = ({
  analytics,
  contactsChartData,
  companyUsers,
  filterUser,
  setFilterUser,
  filterInitialDate,
  setFilterInitialDate,
  filterEndDate,
  setFilterEndDate,
  filterGroupBy,
  setFilterGroupBy
}: AnalyticValuesProps) => {
  // Hooks initialization
  const isAdmin = useIsAdmin();
  const alert = useAlert();
  const [suppliers, setSuppliers] = React.useState<Supplier[]>([]);

  const tabs = [
    { id: "stock-analysis", name: "Análise de Stock" },
    { id: "car-management", name: "Gestão de Carros" },
    { id: "contact-management", name: "Gestão de Contatos" },
    { id: "supplier-management", name: "Gestão de Fornecedores" }
  ];

  const groupByOpts = [
    { id: "", name: "Default" },
    { id: "month", name: "Mês" },
    { id: "year", name: "Ano" }
  ];

  const [isLoaded, setIsLoaded] = React.useState(false);
  const [tabSelected, setTabSelected] = React.useState(0);

  const { company } = useSelector(
    (state: IStoreInterface) => state.adminReducer
  );

  React.useEffect(() => {
    if (analytics) {
      setIsLoaded(true);
    }
  }, [analytics]);

  React.useEffect(() => {
    try {
      if (company) {
        AdminApi.methods.getSuppliers(company.uuid).then(
          (res) => {
            setSuppliers(res.data.suppliers);
          },
          (e) => {
            alert.error("Error fetching the suppliers list");
          }
        );
      }
    } catch (e) {
      alert.error("Erro ao listar os fornecedores. Por favor tente mais tarde");
    }
  }, [alert, company]);

  const theme = createMuiTheme({
    overrides: {
      MuiAppBar: {
        colorPrimary: {
          backgroundColor: "transparent", // Transparent background for AppBar
          borderBottom: "1px solid #e8e8e8",
          boxShadow: "none"
        }
      },
      MuiTab: {
        root: {
          // Custom styling for the Tab label
          "& .MuiTab-label": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column"
          }
        }
      }
    }
  });

  function a11yProps(index: number) {
    return {
      id: `management-tab-${index}`,
      "aria-controls": `management-tabpanel-${index}`
    };
  }

  return isLoaded && isAdmin ? (
    <ThemeProvider theme={theme}>
      <AppBar position="static">
        <Tabs
          value={tabSelected}
          onChange={(event, newValue) => setTabSelected(newValue)}
          aria-label="management tabs"
        >
          {tabs?.map((elem, index) => (
            <Tab
              label={<TabsRowLabel>{elem?.name}</TabsRowLabel>}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </AppBar>
      <TabPanel value={tabSelected} index={0}>
        <CustomColumn>
          <Row>
            <RowLabel>Margem média do stock</RowLabel>
            <RowValue>
              {euroFormatter.format(
                analytics ? analytics.averageStockMargin : 0
              )}
            </RowValue>
          </Row>
          <Separator />
          <Row>
            <RowLabel>Número de carros em stock</RowLabel>
            <RowValue>{analytics?.numberCarsStock}</RowValue>
          </Row>
          <Separator />
          <Row>
            <RowLabel>Tempo médio em stock</RowLabel>
            <RowValue>
              {Math.floor(analytics ? analytics?.averageStockTime : 0)} dias
            </RowValue>
          </Row>
          <Separator />
          <Row>
            <RowLabel>Valor total do stock</RowLabel>
            <RowValue>
              {euroFormatter.format(analytics ? analytics.totalValueStock : 0)}
            </RowValue>
          </Row>
        </CustomColumn>
      </TabPanel>
      <TabPanel value={tabSelected} index={1}>
        <CustomRow>
          <Row>
            <RowLabel>Número de carros com:</RowLabel>
          </Row>
        </CustomRow>
        <CustomRow>
          <Row>
            <RowLabelSecond>Menos de 6 meses:</RowLabelSecond>
            <RowValue>{analytics?.carLessSixMonths}</RowValue>
            <RowLabelSecond>Mais de 6 e menos de 12 meses:</RowLabelSecond>
            <RowValue>{analytics?.carSixToTwelveMonths}</RowValue>
            <RowLabelSecond>Mais de 12 meses:</RowLabelSecond>
            <RowValue>{analytics?.carMoreTwelveMonths}</RowValue>
          </Row>
        </CustomRow>
        <Separator />
        <CustomRow>
          <Row>
            <RowLabel>Número de carros por tipo de combustível:</RowLabel>
          </Row>
        </CustomRow>
        <CustomRow>
          <Row>
            {analytics?.numberCarsFuel.map((elem) => {
              return (
                <RowValue>
                  <RowLabelSecond>
                    {TranslationUtils.getFuelTypeTranslation(elem.typeAnalysis)}
                  </RowLabelSecond>
                  <RowValue>{elem.count}</RowValue>
                </RowValue>
              );
            })}
          </Row>
        </CustomRow>
        <Separator />
        <CustomRow>
          <Row>
            <RowLabel>Número de carros por marca:</RowLabel>
          </Row>
        </CustomRow>
        <CustomRowBrand>
          <Row>
            {analytics?.numberCarsBrand.map((elem) => {
              return (
                <RowValue>
                  <RowLabelSecond>{elem.typeAnalysis}</RowLabelSecond>
                  <RowValue>{elem.count}</RowValue>
                </RowValue>
              );
            })}
          </Row>
        </CustomRowBrand>
        <Separator />
        <CustomRow>
          <Row>
            <RowLabel>Número de carros por stand:</RowLabel>
          </Row>
        </CustomRow>
        <CustomRow>
          <Row>
            {analytics?.numberCarsStand.map((elem) => {
              return (
                <RowValue>
                  <RowLabelSecond>{elem.typeAnalysis}</RowLabelSecond>
                  <RowValue>{elem.count}</RowValue>
                </RowValue>
              );
            })}
          </Row>
        </CustomRow>
      </TabPanel>
      <TabPanel value={tabSelected} index={2}>
        <SearchContainer>
          <WrapRow>
            <RatioContainer ratio={2 / 7}>
              <Row>
                <StyledInput
                  value={filterInitialDate ? filterInitialDate : ""}
                  type="date"
                  placeholder="Data Inicial"
                  onChange={(e) => setFilterInitialDate(e.target.value)}
                />
                <DateSeparator>-</DateSeparator>
                <StyledInput
                  value={filterEndDate ? filterEndDate : ""}
                  type="date"
                  placeholder="Data Final"
                  onChange={(e) => setFilterEndDate(e.target.value)}
                />
              </Row>
            </RatioContainer>
            <RatioContainer ratio={1 / 7}>
              <Dropdown
                placeholder="Utilizador"
                selectValue={(val) => setFilterUser(val.key ?? "")}
                value={filterUser}
                clearValue={() => setFilterUser("")}
                options={DropdownUtils.getAllCompanyUsersDropdownOptions(
                  companyUsers
                )}
              />
            </RatioContainer>
            <RatioContainer ratio={2 / 7}>
              <Row>
                <RowValue>Agrupar:</RowValue>
                {groupByOpts?.map((group, index) => (
                  <Checkbox
                    key={`filter-group-${index}`}
                    checked={filterGroupBy === group?.id}
                    disabled={false}
                    onChange={() => setFilterGroupBy(group?.id)}
                    value={group?.id}
                    label={group?.name}
                  />
                ))}
              </Row>
            </RatioContainer>
          </WrapRow>
        </SearchContainer>
        <CustomRow>
          <LineChart
            width={900}
            height={300}
            data={contactsChartData}
            margin={{ top: 5, right: 40, left: 20, bottom: 20 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="date"
              label={{
                value: "Data de criação",
                position: "outside",
                dy: 15
              }}
              tick={{ fontSize: 12 }}
            />
            <YAxis
              label={{
                value: "Número de novos contatos",
                angle: -90,
                position: "outside",
                dx: -15
              }}
              tick={{ fontSize: 12 }}
            />
            <Tooltip
              labelStyle={{ color: "#afc1ce" }} // Customize label color
              itemStyle={{ color: "#44566c" }} // Customize item color
              formatter={(value: any) => [`${value}`, "Total de Contatos"]} // Customize tooltip content
              labelFormatter={(value: any) => [`Data: ${value}`]}
            />
            <Legend
              payload={[
                {
                  value: "Número de novos contatos",
                  type: "line",
                  id: "value",
                  color: "#ff0000"
                }
              ]}
              verticalAlign="top"
            />
            <Line
              type="monotone"
              dataKey="value"
              stroke="#ff0000"
              name="Novos Contatos"
            />
          </LineChart>
        </CustomRow>
      </TabPanel>
      <TabPanel value={tabSelected} index={3}>
        <CompanySuppliers
          suppliers={suppliers ?? undefined}
          companyId={company ? company.uuid : ""}
        />
      </TabPanel>
    </ThemeProvider>
  ) : null;
};

const SearchContainer = styled(Column as any)`
  ${WrapRow} {
    > div {
      margin-bottom: 36px;
    }
    > div:not(:last-child) {
      margin-right: 24px;
    }
  }
`;

const StyledInput = styled("input")`
  font-family: Merriweather-Light;
  border: 0;
  height: 36px;
  outline: 0;
  border-bottom: 1px solid ${Colors["light-grey-blue"]};
  font-size: 16px;
  ${Typography["Body_Text_#2_Regular_Left"]};
  ::placeholder {
    color: #8996a6;
  }
`;

const CustomColumn = styled(Column as any)`
  margin-bottom: 24px;
`;

const CustomRow = styled(Row as any)`
  margin-bottom: 24px;
  ${Row} {
    margin-right: 32px;
  }
`;

const CustomRowBrand = styled(Row as any)`
  margin: 16px 0 24px 16px;
  ${Row} {
    margin-right: 32px;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
`;

const TabsRowLabel = styled("span")`
  ${Typography.H2_Regular_Left}
  font-size: 18px;
  padding: 4px;
  text-transform: none;
`;

const RowLabel = styled("span")`
  ${Typography.H3_Regular_Left}
  font-size: 16px;
  padding: 8px 16px 8px 16px;
  min-width: 300px;
`;

const RowLabelSecond = styled("span")`
  ${Typography.H3_Regular_Left}
  font-size: 12px;
  margin-right: 4px;
  margin-left: 32px;
`;

const RowValue = styled("span")`
  ${Typography["Body_Text_#1_Regular_Left"]}
  font-size: 14px;
`;

const Separator = styled("div")`
  width: 100%;
  height: 1.5px !important;
  background-color: #ffcc33;
  margin-bottom: 24px;
`;

const DateSeparator = styled("span")`
  margin: 0px 16px;
`;
