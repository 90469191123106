import React from "react";
import {
  Section,
  SectionTitle,
  Row,
  Column,
  SectionSeparator,
  SmallSectionTitle
} from "../../shared/shared.styled";
import UploadPhotos from "./upload-photos.component";
import UploadVideo from "./upload-video.component";
import styled from "styled-components";
import ImageGallery from "./image-gallery.component";
import VideoGallery from "./video-gallery.components";
import { AdminApi } from "../admin.api";
import { useDispatch } from "react-redux";
import { AdminActions } from "../admin.store";
import { CreateVehicleRequest } from "../admin.interfaces";
import { useFormikContext } from "formik";
import SmallButton from "../../shared/components/small-button.component";
import PlusIcon from "../../assets/icons/plus.icon";
import { Colors, Typography } from "../../configs/styled.config";
import { useModal } from "react-modal-hook";
import { VehicleFile } from "../../shared/shared.models";
import { FilesTable } from "./files-table.component";
import { AddFileModal } from "./modals/add-file.modal";
import { useAlert } from "react-alert";
import { ConfirmationModal } from "../../shared/components/modals/confirmation.modal";

export interface SelectedFile {
  file: VehicleFile;
  fileIdx: number;
}
interface MediaSectionProps {
  vehicleId: string;
  isEditing?: boolean;
}

export const MediaSection = ({
  vehicleId,
  isEditing = true
}: MediaSectionProps) => {
  // Hooks
  const dispatch = useDispatch();
  const alert = useAlert();

  const { values, setFieldValue } = useFormikContext<CreateVehicleRequest>();

  // Local state
  const [isUploading, setIsUploading] = React.useState(false);
  const [selectedFileState, setSelectedFileState] = React.useState<
    SelectedFile | undefined
  >(undefined);
  const [selectedFileToDelete, setSelectedFileToDelete] = React.useState<
    number
  >(0);
  const [localFiles, setLocalFiles] = React.useState<VehicleFile[]>(
    values.files || []
  );

  React.useEffect(() => {
    setFieldValue("files", localFiles);
  }, [localFiles, setFieldValue]);

  const [showModal, hideModal] = useModal(
    () => (
      <AddFileModal
        selectedFile={selectedFileState}
        hideModal={hideModal}
        addFile={addFile}
        editFile={editFile}
      />
    ),
    [selectedFileState]
  );

  function uploadPhotos(files: Array<File>) {
    // This will return all the photos of a specific vehicle
    return new Promise<void>(async (resolve, reject) => {
      setIsUploading(true);
      try {
        const res = await AdminApi.methods.uploadPhotos(vehicleId, files);
        // Update the photos in the redux store
        // This will trigger a re-render on our form, since our form is connected to the redux store
        setFieldValue("photos", res.data.photos);
        dispatch(
          AdminActions.methods.updateVehiclePhotosSuccessAction(
            vehicleId,
            res.data.photos
          )
        );

        resolve();
      } catch (e) {
        reject();
      } finally {
        setIsUploading(false);
      }
    });
  }

  function uploadFile(file: File) {
    // This will return the video of a specific vehicle
    return new Promise<void>(async (resolve, reject) => {
      setIsUploading(true);
      try {
        const res = await AdminApi.methods.uploadFile(vehicleId, file);
        // Update the photos in the redux store
        // This will trigger a re-render on our form, since our form is connected to the redux store
        setFieldValue("video", res.data.video);
        dispatch(
          AdminActions.methods.updateVehicleVideoSuccessAction(
            vehicleId,
            res.data.video
          )
        );
        resolve();
      } catch (e) {
        reject();
      } finally {
        setIsUploading(false);
      }
    });
  }

  /**
   * Adds a file to our formik values
   * @param values {VehicleFile}
   */
  const addFile = React.useCallback(
    async (val: VehicleFile, fileUploaded: File) => {
      try {
        const res = await AdminApi.methods.uploadFileVehicle(
          vehicleId,
          fileUploaded,
          val.description
        );
        dispatch(
          AdminActions.methods.createVehicleFileSuccessAction(res.data.file)
        );
        alert.success("File was added successfully");
        setLocalFiles((prevFiles) => [...prevFiles, res.data.file]);
        hideModal();

        return res.data.file; // Return the result if needed by the caller
      } catch (error) {
        alert.error("Error adding file");
        throw error; // Propagate error if needed by the caller
      }
    },
    [alert, dispatch, hideModal, vehicleId, setFieldValue]
  );

  const deleteFile = React.useCallback(async () => {
    if (values.files) {
      try {
        const fileToDelete = values.files[selectedFileToDelete];
        if (fileToDelete.uuid) {
          await AdminApi.methods.deleteFile(fileToDelete.uuid);

          setLocalFiles((prevFiles) =>
            prevFiles.filter((file) => file.uuid !== fileToDelete.uuid)
          );

          hideConfirmationModal();
          alert.success("Ficheiro eliminado com sucesso");
        }
      } catch (e) {
        alert.error("Erro ao eliminar o ficheiro. Por favor tente mais tarde");
      }
    }
  }, [selectedFileToDelete, values.files, alert, setFieldValue]);

  /**
   * Replaces a file with the new values
   * @param obj SelectedFile
   */
  const editFile = React.useCallback(
    (obj: SelectedFile) => {
      let isMounted = true; // Track if component is mounted

      if (obj.file.uuid && obj.file.description) {
        AdminApi.methods
          .updateFileVehicle(obj.file.uuid, obj.file.description)
          .then((res) => {
            if (isMounted) {
              // Only update if component is still mounted
              dispatch(
                AdminActions.methods.updateVehicleFileSuccessAction(
                  vehicleId,
                  res.data.file
                )
              );
              alert.success("File was updated successfully");
              const previousFiles = values.files ?? [];
              previousFiles.splice(obj.fileIdx, 1, res.data.file);
              setFieldValue("files", previousFiles);
              setSelectedFileState(undefined);
              hideModal();
              return "updated";
            }
          })
          .catch((error) => {
            if (isMounted) {
              alert.error("Error updating file");
              return undefined;
            }
          });
      }

      // Cleanup function to set isMounted to false
      return () => {
        isMounted = false;
      };
    },
    [alert, dispatch, hideModal, setFieldValue, values.files, vehicleId]
  );

  const showEditFileModal = (fileIdx: number) => {
    const { files } = values;
    if (files) {
      const file = files[fileIdx];
      setSelectedFileState({ file, fileIdx });
      showModal();
    }
  };

  const [showConfirmationModal, hideConfirmationModal] = useModal(
    () => (
      <ConfirmationModal
        title="Está prestes a eliminar um ficheiro"
        description="Tem a certeza que quer eliminar este ficheiro?"
        onCancel={hideConfirmationModal}
        onClick={deleteFile}
      />
    ),
    [deleteFile]
  );

  return (
    <Section>
      <SectionTitle>Media</SectionTitle>
      <SectionSeparator />
      <ResponsiveRowColumn style={{ justifyContent: "space-between" }}>
        {isEditing ? (
          <Column style={{ flex: 0.4, alignSelf: "flex-start" }}>
            <SmallSectionTitle style={{ marginBottom: 36 }}>
              Upload de ficheiros
            </SmallSectionTitle>
            <Description>
              Nesta secção podes gerir todo o tipo de media relativo a este
              veículo.{" "}
              <p>
                Para fazer upload de novas imagens, basta carregar no botão de
                editar, e depois carregar no botão <span>+</span> ou então
                arrastar as imagens para a área de transferência.
              </p>
              <p>
                Só é possivel selecionar a foto principal depois de guardar a
                informação do veículo
              </p>
            </Description>
            {isEditing ? (
              <UploadPhotos
                isUploading={isUploading}
                handleUpload={uploadPhotos}
              />
            ) : null}
          </Column>
        ) : null}
        <Column
          style={{
            alignSelf: "flex-start",
            flex: isEditing ? 0.5 : 1,
            overflow: "auto"
          }}
        >
          <ImageGallery inEdition={isEditing} />
        </Column>
      </ResponsiveRowColumn>

      <SectionTitle>Vídeo</SectionTitle>
      <SectionSeparator />
      <ResponsiveRowColumn style={{ justifyContent: "space-between" }}>
        {isEditing ? (
          <Column style={{ flex: 0.4, alignSelf: "flex-start" }}>
            <SmallSectionTitle style={{ marginBottom: 36 }}>
              Upload de vídeo
            </SmallSectionTitle>
            <Description>
              Nesta secção podes gerir o vídeo relativo a este veículo.{" "}
              <p>
                Para fazer upload do vídeo, basta carregar no botão de editar, e
                depois carregar no botão <span>+</span> ou então arrastar o
                vídeo para a área de transferência.
              </p>
            </Description>
            {isEditing ? (
              <UploadVideo
                isUploading={isUploading}
                handleUpload={uploadFile}
              />
            ) : null}
          </Column>
        ) : null}
        <Column
          style={{
            alignSelf: "flex-start",
            flex: isEditing ? 0.5 : 1
          }}
        >
          <VideoGallery inEdition={isEditing} />
        </Column>
      </ResponsiveRowColumn>

      <SectionTitle>
        <Row>
          Ficheiros
          <Row style={{ marginLeft: "auto" }}>
            <SmallButton
              disabled={!isEditing}
              iconComponent={
                <PlusIcon
                  style={{
                    fill: isEditing ? Colors["gold-pmauto"] : "#d8d8d8"
                  }}
                />
              }
              label="Adicionar novo ficheiro"
              onClick={() => {
                setSelectedFileState(undefined);
                showModal();
              }}
            />
          </Row>
        </Row>
      </SectionTitle>
      <Column style={{ marginTop: 16 }}>
        {values.files && values.files.length > 0 ? (
          <FilesTable
            editFile={showEditFileModal}
            showDeleteConfirmationModal={showConfirmationModal}
            setSelectedFileToDelete={setSelectedFileToDelete}
            files={values.files}
            isEditing={isEditing}
          />
        ) : (
          <StyledText>Não existem ficheiros.</StyledText>
        )}
      </Column>
    </Section>
  );
};

const Description = styled("span")`
  font-family: Merriweather-Light;
  font-size: 16px;

  > span {
    font-weight: bold;
  }
`;

const ResponsiveRowColumn = styled(Row as any)`
  @media screen and (max-width: 767px) {
    flex-direction: column-reverse;
  }
`;

const StyledText = styled("span")`
  align-self: center;
  ${Typography.H1_Regular_Center};
  font-size: 20px;
  margin-top: 24px;
`;
