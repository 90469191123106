import React from "react";
import styled from "styled-components";
import NotificationIcon from "../../assets/icons/notification.icon";
import AccountIcon from "../../assets/icons/account.icon";
import VehiclesIcon from "../../assets/icons/vehicles.icon";
import CompanyIcon from "../../assets/icons/company.icon";
import { Column } from "../shared.styled";
import { usePath, navigate } from "hookrouter";
import { useSelector, useDispatch } from "react-redux";
import { IStoreInterface } from "../../configs/store.config";
import { LoggedUser, CustomNotification } from "../shared.models";
import NotificationsDrawer from "./notifications-drawer.component";
import { Popper, Reference } from "react-popper";
import { Colors, Typography } from "../../configs/styled.config";
import { AdminActions } from "../../admin/admin.store";
import { RoutesEnum } from "../../routes.constants";
import { removeTokenFromClient } from "../../configs/axios.config";
import { RoleEnum } from "../shared.enums";
import SettingsIcon from "../../assets/icons/settings.icon";
import produce from "immer";
import { TrendingUp, AssignmentTurnedIn } from "@material-ui/icons";
import { getUserFullName } from "../shared.utils";
import { AdminApi } from "../../admin/admin.api";
import AnalysisIcon from "../../assets/icons/analysis.icon";
import { makeStyles } from "@material-ui/core/styles";
import ContactIcon from "../../assets/icons/contacts.icon";
import { useIsWorkshop } from "../hooks/use-is-workshop.hook";

const SideMenu = () => {
  const path = usePath();
  const dispatch = useDispatch();
  const classes = useStyles();
  const isWorkshop = useIsWorkshop();
  // hooks
  //const standExistOnly = useHasStandsOnly();

  // Redux state
  const { user, notifications, company } = useSelector(
    (state: IStoreInterface) => state.adminReducer
  );
  // Local state
  const [isDrawerVisible, setDrawerVisible] = React.useState(false);
  const [isAccountMenuVisible, setIsAccountMenuVisible] = React.useState(false);
  const [sortedNotifications, setSortedNotifications] = React.useState<
    CustomNotification[]
  >([]);

  /**
   * Function that is polling the Notifications
   */
  const pollNotifications = React.useCallback(async () => {
    try {
      const response = await AdminApi.methods.getNotifications();
      dispatch(
        AdminActions.methods.getNotificationsSuccessAction(
          response.data.notifications
        )
      );
    } catch (e) {}
  }, [dispatch]);

  React.useEffect(() => {
    pollNotifications();
    const notifInterval = setInterval(pollNotifications, 60000);
    return () => clearInterval(notifInterval);
  }, [pollNotifications]);

  React.useEffect(() => {
    let newNotifications = produce(notifications, (draft) => {
      draft.sort((a: CustomNotification, b: CustomNotification) => {
        if (a.isRead) {
          return 1;
        }
        return -1;
      });
    });
    setSortedNotifications(newNotifications);
  }, [notifications]);

  const unreadNotifs = notifications.filter((n) => !n.isRead);
  const logout = () => {
    removeTokenFromClient();
    dispatch(AdminActions.methods.logoutAction());
    navigate(RoutesEnum.LOGIN);
    localStorage.clear();
  };

  return (
    <>
      <SideMenuContainer>
        <LogoContainer>
          {company && company?.logo !== undefined ? (
            <img
              className={classes.redBorder}
              src={company?.logo}
              alt="logo_company"
            />
          ) : (
            <img src="/images/logo_digiauto.svg" alt="logo_normal" />
          )}
        </LogoContainer>
        <MainNavLinks>
          {user?.isSuperUser === true ? (
            <IconContainer
              onClick={() => navigate(RoutesEnum.COMPANIES_LIST)}
              active={new RegExp(/\/superuser\/^companies/).test(path)}
            >
              <CompanyIcon />
            </IconContainer>
          ) : null}
          {user?.isSuperUser === false ? (
            <IconContainer
              onClick={() => navigate(RoutesEnum.VEHICLES_LIST)}
              active={new RegExp(/\/admin\/vehicle*/).test(path)}
            >
              <VehiclesIcon />
            </IconContainer>
          ) : null}
          {user?.isSuperUser === false &&
          (user?.role === RoleEnum.ADMIN || user?.role === RoleEnum.MANAGER) ? (
            <IconContainer
              onClick={() => navigate(RoutesEnum.ADMIN_VEHICLES)}
              active={new RegExp(/\/admin\/admin-vehicles*/).test(path)}
            >
              <SettingsIcon />
            </IconContainer>
          ) : null}
          {user?.isSuperUser === false ? (
            <IconContainer
              onClick={() => navigate(RoutesEnum.SOLD_VEHICLES)}
              active={new RegExp(/\/admin\/sold-vehicles*/).test(path)}
            >
              {user?.role === RoleEnum.SELLER ? (
                <AssignmentTurnedIn />
              ) : (
                <TrendingUp />
              )}
            </IconContainer>
          ) : null}
          {user?.isSuperUser === false && user?.role === RoleEnum.ADMIN && (
            <IconContainer
              onClick={() => navigate(RoutesEnum.ANALYTICS)}
              active={new RegExp(/\/admin\/analytics*/).test(path)}
            >
              <AnalysisIcon />
            </IconContainer>
          )}
        </MainNavLinks>
        <SecondaryNavLinks>
          <NotificationIconContainer
            onClick={() => setDrawerVisible(true)}
            hasNotifications={!!unreadNotifs.length}
          >
            <NotificationIcon />
            {unreadNotifs.length > 0 ? (
              <NotificationNumber>
                {unreadNotifs.length > 9 ? "+9" : unreadNotifs.length}
              </NotificationNumber>
            ) : null}
          </NotificationIconContainer>
          {user?.isSuperUser === false && !isWorkshop && (
            <IconContainer
              onClick={() => navigate(RoutesEnum.CONTACTS)}
              active={new RegExp(/\/account\/contacts*/).test(path)}
            >
              <ContactIcon />
            </IconContainer>
          )}
          <Reference>
            {({ ref }) => (
              <IconContainer
                onClick={() => setIsAccountMenuVisible(!isAccountMenuVisible)}
                ref={ref}
              >
                <AccountIcon />
              </IconContainer>
            )}
          </Reference>
        </SecondaryNavLinks>
      </SideMenuContainer>
      <NotificationsDrawer
        isDrawerVisible={isDrawerVisible}
        setDrawerVisibility={setDrawerVisible}
        notifications={sortedNotifications}
        user={user as LoggedUser}
      />
      {isAccountMenuVisible ? (
        <Popper placement="right-end" positionFixed={true}>
          {({ ref, placement, style }) => (
            <PopperContainer style={style} data-placement={placement} ref={ref}>
              <AccountMenu>
                <Username>{getUserFullName(user)}</Username>
                <LogoutLink onClick={logout}>Logout</LogoutLink>
              </AccountMenu>
            </PopperContainer>
          )}
        </Popper>
      ) : null}
    </>
  );
};

export default SideMenu;

const PopperContainer = styled(Column as any)`
  z-index: 11;
`;

const AccountMenu = styled(Column as any)`
  background-color: white;
  padding: 8px;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.3);
`;

const Username = styled("span")`
  ${Typography["Body_Text_#3_Regular_Left"]}
  margin-bottom: 24px;
`;

const LogoutLink = styled("span")`
  ${Typography["Body_Text_#3_Regular_Center"]}
  color: ${Colors["gold-pmauto"]};
  cursor: pointer;
`;

const SideMenuContainer = styled("div")`
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 60px;
  height: 100%;
  background-color: #fafbfb;
  align-items: center;
  z-index: 10;
  padding: 0px 4px 0 4px;
`;

const LogoContainer = styled("div")`
  margin-top: 32px;

  img {
    width: 100%;
    height: auto;
    padding: 0 4px 0 4px;
    border-radius: 4px;
  }
`;

const MainNavLinks = styled(Column as any)`
  margin-top: 60px;
  > div:not(:last-child) {
    margin-bottom: 24px;
  }

  svg {
    width: 24px;
    height: 24px;
  }
`;

const SecondaryNavLinks = styled(Column as any)`
  margin-top: auto;
  margin-bottom: 36px;
  > div:not(:last-child) {
    margin-bottom: 16px;
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;

const IconContainer = styled("div")<{ active?: boolean }>`
  width: 36px;
  height: 36px;
  cursor: pointer;
  stroke: ${(props) => (props.active ? "white" : "#afc1ce")};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  svg {
    fill: ${(props) => (props.active ? "white" : "#afc1ce")};
  }

  ${(props) =>
    props.active
      ? `
    background-color: ${Colors["gold-pmauto"]};
    border-radius: 50%
  `
      : ""}
`;

// const NeededIconContainer = styled("div")<{
//   active?: boolean;
//   standExist?: boolean;
// }>`
//   width: 36px;
//   height: 36px;
//   cursor: pointer;
//   stroke: ${(props) => (props.standExist ? "white" : "#C80303")};
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   position: relative;

//   svg {
//     fill: ${(props) => (props.standExist ? "#afc1ce" : "#C80303")};
//   }

//   ${(props) =>
//     props.active
//       ? `
//     background-color: ${Colors["gold-pmauto"]};
//     border-radius: 50%
//     svg {
//       fill:white;
//     }
//   `
//       : ""}
// `;

interface NotificationIconContainerProps {
  hasNotifications: boolean;
}

const NotificationIconContainer = styled("div")<NotificationIconContainerProps>`
  width: 36px;
  height: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  stroke: ${(props) => (props.hasNotifications ? "#ec1c24" : "#b0c1cf")};
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
  }
`;

const NotificationNumber = styled("div")`
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #ff0000;
  top: 0px;
  right: 0px;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  color: white;
`;

const useStyles = makeStyles({
  redBorder: {
    border: "1px solid red",
    width: "40px",
    height: "40px"
  },
  paddingImg: {
    padding: "4px"
  }
});
