import React from "react";
import { useRoutes, useRedirect } from "hookrouter";
import AdminDashboardPage from "./admin/pages/dashboard.page";
import SuperAdminDashboardPage from "./admin/pages/super-dashboard.page";
import VehicleAdminPage from "./admin/pages/vehicle-admin.page";
import CreateVehiclePage from "./admin/pages/create-vehicle.page";
import CreateStandPage from "./admin/pages/create-stand.page";
import CreateCompanyPage from "./admin/pages/create-company.page";
import CreateUserPage from "./admin/pages/create-user.page";
import { StandPage } from "./admin/pages/stand.page";
import { CompanyPage } from "./admin/pages/company.page";
import { UserPage } from "./admin/pages/user.page";
import SideMenu from "./shared/components/side-menu.component";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { AdminApi } from "./admin/admin.api";
import { AdminActions } from "./admin/admin.store";
import { useAlert } from "react-alert";
import { SoldVehiclesPage } from "./admin/pages/sold-vehicles.page";
import { VehiclePage } from "./admin/pages/vehicle.page";
import { RoutesEnum } from "./routes.constants";
import { IStoreInterface } from "./configs/store.config";
import { LoggedUser } from "./shared/shared.models";
import { RoleEnum } from "./shared/shared.enums";
import { AnalyticsPage } from "./admin/pages/analytics.page";
import { ContactsPage } from "./admin/pages/contacts.page";
import CreateContactPage from "./admin/pages/create-contact.page";
import { ContactPage } from "./admin/pages/contact.page";
import CreateSupplierPage from "./admin/pages/create-supplier.page";
import { SupplierPage } from "./admin/pages/supplier.page";

const authenticatedRoutes = {
  "/admin/vehicles": () => <AdminDashboardPage />,
  "/admin/admin-vehicles": () => <VehicleAdminPage />,
  "/admin/vehicle-details/:vehicleId*": (params: any) => (
    <VehiclePage vehicleId={params.vehicleId} />
  ),
  "/admin/vehicles/create": () => <CreateVehiclePage />,
  "/admin/sold-vehicles": () => <SoldVehiclesPage />,
  "/admin/analytics/": () => <AnalyticsPage />,
  "/account/contacts/": () => <ContactsPage />,
  "/admin/contacts/:contactId*": (params: any) => (
    <ContactPage contactId={params.contactId} />
  ),
  [RoutesEnum.CREATE_CONTACT]: () => <CreateContactPage />,
  [RoutesEnum.CREATE_VEHICLE]: () => <CreateVehiclePage />,
  [RoutesEnum.CREATE_STAND]: (params: any) => (
    <CreateStandPage companyId={params.companyId} />
  ),
  [RoutesEnum.CREATE_SUPPLIER]: (params: any) => (
    <CreateSupplierPage companyId={params.companyId} />
  ),
  "/superuser/stand-details/:standId*": (params: any) => (
    <StandPage standId={params.standId} />
  ),
  "/superuser/supplier-details/:supplierId*": (params: any) => (
    <SupplierPage supplierId={params.supplierId} />
  ),
  "/superuser/companies": () => <SuperAdminDashboardPage />,
  [RoutesEnum.CREATE_COMPANY]: () => <CreateCompanyPage />,
  "/superuser/company-details/:companyId*": (params: any) => (
    <CompanyPage companyId={params.companyId} />
  ),
  "/account/user/:userId*": (params: any) => (
    <UserPage userId={params.userId} />
  ),
  [RoutesEnum.CREATE_USER]: (params: any) => (
    <CreateUserPage companyId={params.companyId} />
  )
};

const PrivateRoutes = () => {
  // Hooks Initialization
  const dispatch = useDispatch();

  const alert_error = useAlert();
  const loggedUser = useSelector(
    (state: IStoreInterface) => state.adminReducer.user as LoggedUser
  );

  let pathInitial = "";

  if (loggedUser.isSuperUser) pathInitial = "/superuser/companies";
  else if (loggedUser.isActive) pathInitial = "/admin/vehicles";
  else pathInitial = "/login";
  useRedirect("/", "" + pathInitial + "");
  /**
   * Function to fetch a list of users
   */
  const fetchUsers = React.useCallback(() => {
    if (
      loggedUser.companyUuid &&
      (loggedUser.role === RoleEnum.ADMIN ||
        loggedUser.role === RoleEnum.MANAGER ||
        loggedUser.isSuperUser)
    ) {
      AdminApi.methods
        .getUsers(loggedUser.companyUuid)
        .then((res) => {
          dispatch(AdminActions.methods.getUsersSuccessAction(res.data.users));
        })
        .catch((e) => {
          alert_error.error(
            "Houve um erro a ir buscar a lista de utilizadores, por favor tente mais tarde"
          );
        });
    }
  }, [alert_error, dispatch, loggedUser]);

  /**
   * Function to fetch a list of stands
   */
  const fetchStands = React.useCallback(() => {
    if (loggedUser.companyUuid) {
      AdminApi.methods
        .getStands(loggedUser.companyUuid)
        .then((res) => {
          dispatch(
            AdminActions.methods.getStandsSuccessAction(res.data.stands)
          );
        })
        .catch((e) => {
          alert_error.error(
            "Houve um erro a ir buscar a lista de stands, por favor tente mais tarde"
          );
        });
    }
  }, [alert_error, dispatch, loggedUser]);

  /**
   * Function to fetch the list of extras
   */
  const fetchCategories = React.useCallback(() => {
    AdminApi.methods
      .getCategories()
      .then((res) => {
        dispatch(
          AdminActions.methods.getCategoriesSuccessAction(res.data.categories)
        );
      })
      .catch((e) => {
        alert_error.error(
          "Houve um erro a ir buscar a lista de extras, por favor tente mais tarde"
        );
      });
  }, [alert_error, dispatch]);

  /**
   * Fetchs the list of cost types
   */
  const fetchCostTypes = React.useCallback(() => {
    AdminApi.methods
      .getCostTypes()
      .then((res) => {
        dispatch(
          AdminActions.methods.getCostTypesSuccessAction(res.data.costTypes)
        );
      })
      .catch((e) => {
        alert_error.error(
          "Houve um erro a ir buscar os tipos de custo, por favor tente mais tarde"
        );
      });
  }, [alert_error, dispatch]);

  // Effects
  React.useEffect(() => {
    fetchCategories();
    fetchUsers();
    fetchStands();
    fetchCostTypes();
  }, [fetchUsers, fetchStands, fetchCategories, fetchCostTypes]);

  const routesResult = useRoutes(authenticatedRoutes);

  return (
    <>
      <SideMenu />
      <MainContainer>{routesResult || "404 not found"}</MainContainer>
    </>
  );
};

export default PrivateRoutes;

const MainContainer = styled("div")`
  margin-left: 60px;
  padding: 32px;
  min-height: 500px;
`;
